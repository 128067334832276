import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SupportService, ConfigurationService, ProfileService } from '@central/ng-shared';

@Component({
	selector: 'central-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
	public hasPremiumSupport = false;
	public messageRequiresPremium = false;
	public username;
	public subject: string;
	public description: string;
	public selectedType = 'flex_metal_help';
	public priority = 'normal';
	public selectedEnvironment;
	public cloud;
	public cloudId: string;
	public department: string;
	public clarityHours;
	public clarityMinutes;
	public typeOptions = [
		{ value: 'flex_metal_help', label: 'Help' },
		{ value: 'flex_metal_feedback', label: 'Feedback' },
	];

	public priorityOptions = [];
	public departmentOptions = [
		{ value: 'technical_support', label: 'Technical Support' },
		{ value: 'billing', label: 'Billing' },
	];
	@Input() public state = 'pending';
	@Output() public submitTicket = new EventEmitter();

	constructor(
		public matDialogRef: MatDialogRef<MessageComponent>,
        public router: Router,
        public configService: ConfigurationService,
		public supportService: SupportService,
		public profileService: ProfileService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	public ngOnInit(): void {
		this.typeOptions = this.data.typeOptions ?
			this.data.typeOptions : this.typeOptions;

		this.selectedType = this.data.selectedType ?
		this.data.selectedType : this.selectedType;

		this.department = this.data?.userSetting?.department ?
		this.data?.userSetting?.department : this.department;

		this.selectedEnvironment = this.data?.selectedEnvironment?.id;

		this.getPriorities();

		this.cloud = this.data.cloud;
		this.cloudId = this.cloud?.id;
		const account = this.data.account;
		this.username = account.name || account.display_name;
		this.hasPremiumSupport = !!(this.cloud
			? this.cloud.fields.support_plan
			: null);

		if (
			this.data.userSetting &&
			this.data.userSetting.user &&
			this.data.userSetting.requiresPremium
		) {
			this.messageRequiresPremium = true;
		}

		if ( this.data?.hasMH ) {
			this.departmentOptions.push(
				{ value: 'managed_hosting', label: 'Professional Services' }
			);
			const hid = this.data?.selectedEnvironment?.fields?.clarity_hid;
			this.supportService.getAvailableClarityHours(hid).subscribe((minutes) => {
				this.clarityMinutes = minutes;
				this.clarityHours = (this.clarityMinutes/60) - ((this.clarityMinutes/60) % 1);
			});
		}
	}

	public updateClarityHours(event) {
		if ( !this.data?.hasMH ) {
			return;
		}
		this.data?.environments.forEach((env) => {
			if (env.id === this.selectedEnvironment) {
				const hid = env.fields?.clarity_hid;
				this.supportService.getAvailableClarityHours(hid).subscribe((minutes) => {
					this.clarityMinutes = minutes;
					this.clarityHours = (this.clarityMinutes/60) - ((this.clarityMinutes/60) % 1);
				});
			}
		});
	}

	public upgrade() {
		this.router.navigate(['/clouds', this.cloudId, 'addons']);
		this.matDialogRef.close();
	}

	public canContact() {
		return (
			(this.data.userSetting && this.hasPremiumSupport) ||
			!this.messageRequiresPremium
		);
	}

	public hasInput(input: string) {
		return this.data.inputs.includes(input);
	}

	public submit() {
		const ticket_data = {
			subject: this.subject,
			description: this.description,
			priority:
				'flex_metal_help' === this.selectedType
					? this.priority
					: 'normal',
			type: this.selectedType,
			assignee_id: this.data.userSetting?.user?.id,
			group: this.department,
			cloud_id: this.cloudId,
			env_id: this.selectedEnvironment,
		};

		this.submitTicket.emit(ticket_data);
	}
	private getPriorities() {
		this.priorityOptions = this.data.priorities;
	}
}
