import { Router, NavigationEnd } from '@angular/router';
import { Inject, Injectable, Injector, OnDestroy, ProviderToken } from '@angular/core';
import { filter } from 'rxjs/operators';
import { DecoderService } from '../core/jwt/decoder';
import { CENTRAL_ENVIORNMENT_CONFIG } from '../core/configuration.service';
import { ApiService } from '../core';
import { ReplaySubject, Subscription } from 'rxjs';
import { AuthService } from '../authentication/services';

@Injectable({
	providedIn: 'root',
})
export class TrackingService implements OnDestroy {
	private history = [];
	
	public onEvent = new ReplaySubject<{ event: string; data: object }>();
	public onEvent$ = this.onEvent.asObservable();
	private onSetUser = new ReplaySubject<[number, string]>(1);
	public onSetUser$ = this.onSetUser.asObservable();
	public onAppendScript = new ReplaySubject<string>();
	public onAppendScript$ = this.onAppendScript.asObservable();
	public dynamicScripts = {
		hotjar: {
			loader: window['BG_Hotjar'],
			isLoaded: false,
		},
	};
	public errorEvent: Subscription;
	public services: any[] = [];

	constructor(
		private router: Router,
		private apiService: ApiService,
		private authService: AuthService,
		private jwtHelper: DecoderService,
	) {
		this.errorEvent = this.apiService.trackError$.subscribe({next:(err) => this.track('error', err)});
		this.authService.onLogin.subscribe(() => this.track('signin'));

		this.setUser();

		// Request scripts after application is fully loaded.
		// setTimeout( () => this.scriptService.loadScript( 'getResponse' ), 1000 );
	}
	ngOnDestroy(): void {
		this.errorEvent?.unsubscribe();
	}

	/**
	 * Listen to router events “navigation” and saves each route into an array.
	 *
	 */
	public loadRouting(): void {
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(({urlAfterRedirects}: NavigationEnd) => {
				this.history = [...this.history, urlAfterRedirects];
			});
	}
	public appendScripts(): void {
		this.onAppendScript.next();
	}
	public getHistory(): string[] {
		return this.history;
	}

	public getPreviousUrl(): string {
		return this.history[this.history.length - 2] || '/';
	}

	public getCurrentUrl(): string {
		return this.history[this.history.length - 1] || '/';
	}

	/**
	 * Set the current user within analytic tracking objects.
	 *
	 * @since 1.4.0
	 */
	public setUser() {
		const accountId = this.jwtHelper.getTokenVal('user_id');
		const email = this.jwtHelper.getTokenVal('email');

		if (accountId) {
			this.onSetUser.next([accountId, email]);
		}
	}


	/**
	 * General Tracking call.
	 *
	 * Everything comes in through this call. Idea is that all global tracking processes first pass through here.
	 *
	 * @since 1.1
	 *
	 * @param      event Method to call.
	 * @param      data Data to be used for tracking.
	 */
	public track = (event: string, data?: object) => this.onEvent.next({ event, data });
}
