import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AccountService } from '../../../account/account.service';
import { ApiService } from '../../../core/api/api.service';
import { AuthService } from '../../services';
import { DeviceOption } from '../two-factor-auth.component';
import { HttpClient } from '@angular/common/http';
import { TwoFaState } from '../two-factor-auth.component';
import { RemoveDeviceComponent } from './remove-device/remove-device.component';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfigurationService } from '@central/ng-shared';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

export enum updateDeviceState {
	pending = 'pending',
	enableDevice = 'enableDevice',
	validateCode = 'validateCode',
	recoveryCodes = 'recoveryCodes',
}

export enum sendCodeState {
	pending = 'pending',
	submitted = 'submitted',
	failed = 'failed',
	success = 'success',
}

@Component({
	selector: 'central-enable-two-factor',
	templateUrl: './enable-two-factor.component.html',
	styleUrls: ['./enable-two-factor.component.scss'],
})
export class EnableTwoFactorComponent implements OnInit {
	@ViewChild('twoFaModal') public twoFaModal: TemplateRef<any>;
	@ViewChild('deleteConfirm') public deleteConfirm: TemplateRef<any>;
	@ViewChild('phoneNumber') public phoneNumberField;
	@ViewChild('emailField') public emailField;

	public emailInput = '';
	public hasTwoFa = false;
	public sendError: string;
	public sendCodeError;
	public state: updateDeviceState = updateDeviceState.pending;
	public sendCodeState = 'pending';
	public displayedColumns: string[] = ['name', 'message', 'action'];
	public dataSource: DeviceOption[];
	public twoFaUserData;
	public deviceToUpdate: DeviceOption;
	public deviceAction: string;
	public recoveryCodes = [];
	public verifyState = 'pending';
	public settings;
	public fetchSettingsState = 'loading';

	constructor(
		public dialog: MatDialog,
		public configService: ConfigurationService,
		private apiService: ApiService,
		private accountService: AccountService,
		private authService: AuthService,
		private httpClient: HttpClient,
		private clipboard: Clipboard,
		private snackbar: MatSnackBar,

	) {}

	public ngOnInit(): void {
		this.updateTableData();
	}

	public updateDevice(device: DeviceOption, deviceAction) {
		if (deviceAction === 'enable-device') {
			this.state = updateDeviceState.enableDevice;
		}

		this.deviceToUpdate = device;
		this.deviceAction = deviceAction;
		this.openDialog();
	}

	public deleteDevice(device) {
		const deleteRef = this.dialog.open(RemoveDeviceComponent, {
			data: {
				device,
			},
		});

		// On close
		deleteRef.afterClosed().subscribe(() => {
			this.updateTableData();
		});
	}

	public handleVerifyCodeSubmit(twoFaFormData) {
		const sendTo =
			this.deviceToUpdate.name === 'email'
				? this.emailField.value
				: this.phoneNumberField.getNumber();
		const request = {
			method: 'update-twofactor',
			type: this.deviceToUpdate.name,
			sendTo: sendTo,
		};

		const url = this.apiService.formatter.getUrl('/v1/twofactor/device');
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
			multifactor: twoFaFormData.multifactor,
		});

		this.verifyState = TwoFaState.submitted;

		this.httpClient.post(url, request, { headers }).subscribe({
			next: (response) => {
				if (response['recoveryCodes']) {
					this.recoveryCodes = response['recoveryCodes'];
					this.state = updateDeviceState.recoveryCodes;
				} else {
					this.handletwoFaCancel();
				}

				this.updateTableData();
			},
			error: (error) => {
				this.verifyState = TwoFaState.validateCode;
				this.twoFaUserData.invalidCode = twoFaFormData.multifactor.code;
			},
		});
	}

	public handletwoFaCancel() {
		this.dialog.closeAll();
		this.resetDeviceToUpdate();
	}

	public getDeviceDescription(deviceType) {
		if (deviceType === 'email') {
			return 'email address';
		} else if (deviceType === 'sms') {
			if (this.configService.config.brandConfig.id === 'imh') {
				return 'mobile number';
			}
			return 'phone number';
		}
	}

	public handleSendCodeSubmit() {
		const sendTo =
			this.deviceToUpdate.name === 'email'
				? this.emailField.value
				: this.phoneNumberField.getNumber();
		const request = {
			type: this.deviceToUpdate.name,
			sendTo: sendTo,
		};

		const url = this.apiService.formatter.getUrl(
			'/v1/two-factor/device-precheck'
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		this.sendCodeState = TwoFaState.submitted;

		this.httpClient.post(url, request, { headers }).subscribe({
			next: (response) => {
				this.twoFaUserData.deviceOptions[0].message = sendTo;
				this.state = updateDeviceState.validateCode;
			},
			error: (error) => {
				this.sendCodeState = sendCodeState.failed;
				this.sendError = this.apiService.getPublicError(error);
			},
		});
	}

	public sendCode() {
		this.sendCodeState = 'submitted';
		const sendTo =
			this.deviceToUpdate.name === 'email'
				? this.emailField?.value
				: this.phoneNumberField?.getNumber();

		this.apiService
			.post('/v1/two-factor', {
				type: this.deviceToUpdate.name,
				sendTo: sendTo,
				method: 'update-twofactor',
			})
			.subscribe({
				next: () => {},
				error: (error) => {
					this.sendCodeState = sendCodeState.failed;
					this.sendError = this.apiService.getPublicError(error);
				},
			});
	}

	public copyRecoveryCodes() {
		this.clipboard.copy(this.recoveryCodes.join("\n"));
		this.snackbar.open('Copied Backup Codes to Clipboard!', '', {
			duration: 4000,
		});
	}

	private openDialog(): void {
		this.twoFaUserData = {
			username: this.accountService.account.email,
			deviceOptions: [
				{
					name: this.deviceToUpdate.name,
					message:
						this.deviceToUpdate.name === 'email'
							? this.emailField?.value
							: this.phoneNumberField?.getNumber(),
				},
			],
		};

		const dialogRef = this.dialog.open(this.twoFaModal,{
			disableClose: true,
			width: '500px',
		});

		dialogRef.afterClosed().subscribe((result) => {
			this.resetDeviceToUpdate();
		});
	}

	private updateTableData() {
		this.fetchSettingsState = 'loading';
		this.fetchSettings().subscribe((settings: any[]) => {
			const twoFaData = settings;
			const email = twoFaData?.find((device) => device.type === 'email');
			const phone = twoFaData?.find((device) => device.type === 'sms');

			this.dataSource = [
				{
					name: 'sms',
					message: phone?.contact,
				},
				{
					name: 'email',
					message: email?.contact,
				},
			];
		});
	}

	private resetDeviceToUpdate() {
		this.deviceAction = '';
		this.deviceToUpdate = null;
		this.sendCodeState = sendCodeState.pending;
		this.state = updateDeviceState.pending;
		this.updateTableData();
	}

	private fetchSettings() {
		return this.apiService
			.get('/v1/two-factor/device', {})
			.pipe(
				map((response) => {
					const settings = response;
					this.fetchSettingsState = 'success';

					return settings;
				})
			)
			.pipe(
				catchError(() => {
					this.fetchSettingsState = 'failed';
					return of([]);
				})
			);
	}
}
