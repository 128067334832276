<ng-container *ngIf="this.configService.config.brandConfig.id !== 'imh'">
	<h3 class="ctw-font-bold">
		{{ !this.useBackup ? 'Security Verification:' : 'Two-Factor Recovery' }}
	</h3>
	<p *ngIf="!this.useBackup">
		We do not recognize this device. To verify your identity, we'll send you a
		security code.
	</p>
</ng-container>

<ng-container *ngIf="this.configService.config.brandConfig.id === 'imh'">
	<ng-container *ngIf="this.action === 'update-twofactor'">
		<h2>
			Identity Verification
		</h2>
	</ng-container>
	<ng-container *ngIf="this.action !== 'update-twofactor'">
		<h3 class="ctw-font-bold ctw-text-center">
			{{ !this.useBackup ? 'Security Verification' : 'Account Recovery' }}
		</h3>
		<p *ngIf="!this.useBackup" class="ctw-text-center">
			We do not recognize this device. To verify your identity, we'll send you a
			security code.
		</p>
	</ng-container>
</ng-container>

<central-two-fa-send-code [hidden]="this.state !== 'sendCode' && this.state !== 'failure'"
	[twoFaUserData]="this.twoFaUserData" [sendError]="this.codeFailure" [state]="this.state"
	(cancel)="this.handleCancel()" (sendCode)="sendCode($event)"
	(sendCodeCancel)="this.handleCancel()"></central-two-fa-send-code>

<p *ngIf="this.useBackup" [class.ctw-text-center]="this.configService.config.brandConfig.id === 'imh'">
	Enter one of your {{this.configService.config.brandConfig.id === 'imh' ? 'backup codes generated' : 'recovery codes
	created'}} when
	you {{this.configService.config.brandConfig.id === 'imh' ? 'originally setup' : 'added'}} Two-Factor authentication.
</p>
<p *ngIf="this.useBackup" [class.ctw-text-center]="this.configService.config.brandConfig.id === 'imh'">
	The {{this.configService.config.brandConfig.id === 'imh' ? 'backup' : 'recovery'}} code will be voided after use.
</p>

<form [hidden]="this.state !== 'validateCode' && this.state !== 'submitted'"
	[class.twoFa-form]="this.configService.config.brandConfig.id !== 'imh'" (ngSubmit)="submit()" novalidate>
	<p *ngIf="!this.useBackup">
		Please enter the code sent to
		{{ this.model.deviceSelection?.message }}.
	</p>
	<mat-form-field [appearance]="this.inputAppearance"
		class="twoFaCode-field {{ !this.useBackup && this.configService.config.brandConfig.id === 'imh' ? 'ctw-mb-[-1.25em]' : ''}}">
		<mat-label>{{ !this.useBackup ? 'Verification' : 'Backup' }} Code:</mat-label>
		<input matInput class="validated" name="twoFaCode" [(ngModel)]="model.twoFaCode" autofocus type="text" required
			#twoFaCode="ngModel" />

		<mat-hint align="start" class="animate__animated error" [class.animate__slideInUp]="
				fieldHasError(twoFaCode) ||
				twoFaUserData.invalidCode ||
				this.codeFailure?.name === 'rate_limit_exceeded'
			">
			<span *ngIf="fieldHasError(twoFaCode)">{{ !this.useBackup ? 'An authentication' : 'A backup' }} code
				is required.</span>
			<span *ngIf="!fieldHasError(twoFaCode) && twoFaUserData.invalidCode">The {{ !this.useBackup ?
				'authentication' : 'backup' }} code
				you entered is invalid.</span>
			<span *ngIf="this.codeFailure?.name === 'rate_limit_exceeded'">Number of resubmissions reached. Please try
				again later.</span>
		</mat-hint>
	</mat-form-field>

	<ng-container *ngIf="this.configService.config.brandConfig.id !== 'imh'">
		<div class="row flex actions">
			<div class="col s6 left-align">
				<mat-checkbox *ngIf="this.showRememberMe && !this.useBackup" name="rememberMetwoFa"
					[(ngModel)]="model.rememberMetwoFa" class="remember-me">Remember This Device</mat-checkbox>
			</div>
			<div class="col s6 right-align" *ngIf="!this.useBackup">
				<button type="button" name="resend-code" [disabled]="this.resendIsDisabled === true"
					(click)="this.triggerCode()" class="verify-button" mat-stroked-button color="primary">
					Resend Code
				</button>
			</div>
		</div>

		<button mat-ripple mat-raised-button color="accent" class="btn-spinner send-code-button"
			[disabled]="this.state === 'submitted'">
			<mat-spinner diameter="20" *ngIf="this.state == 'submitted' && this.showBtnSpinner"></mat-spinner>
			Verify Code
		</button>
		<button type="button" [disabled]="this.state === 'submitted'" (click)="this.handleCancel()"
			class="cancel-button" mat-raised-button>
			<mat-icon>arrow_back</mat-icon> Cancel
		</button>
		<button type="button" name="resend-code" class="backup-button" *ngIf="!this.useBackup && this.enableBackup"
			(click)="this.useBackup = 1" mat-stroked-button color="primary">
			Use Recovery Code
		</button>
	</ng-container>

	<ng-container *ngIf="this.configService.config.brandConfig.id === 'imh'">
		<div class="col s6 left-align {{ !this.useBackup ? 'ctw-mb-10' : '' }} ctw-ml-1">
			<mat-checkbox *ngIf="this.showRememberMe && !this.useBackup" name="rememberMetwoFa"
				[(ngModel)]="model.rememberMetwoFa" class="remember-me">Remember This Device</mat-checkbox>
		</div>
		<div
			class="ctw-flex ctw-justify-{{this.useBackup ? 'end' : 'between'}} ctw-mb-0 {{ !this.useBackup ? 'ctw-mt-10' : '' }}">
			<div class="col s6 right-align" *ngIf="!this.useBackup">
				<button type="button" name="resend-code" [disabled]="this.resendIsDisabled === true"
					(click)="this.triggerCode()" class="" mat-stroked-button color="primary">
					Resend
				</button>
			</div>

			<div>
				<button mat-button type="button" [disabled]="this.state === 'submitted'" (click)="this.handleCancel()">
					Cancel
				</button>
				<button mat-ripple mat-raised-button color="primary" class="btn-spinner ctw-ml-3"
					[disabled]="this.state === 'submitted'">
					<mat-spinner diameter="20" *ngIf="this.state == 'submitted' && this.showBtnSpinner"></mat-spinner>
					Verify Code
				</button>
			</div>
		</div>

		<button type="button" name="resend-code" class="backup-button" *ngIf="!this.useBackup && this.enableBackup"
			(click)="this.useBackup = 1" mat-flat-button color="accent">
			Use Recovery Code
		</button>
	</ng-container>
</form>
