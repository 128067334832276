<div class="ctw-w-full ctw-flex ctw-justify-center">
    <div class="ctw-w-full ctw-max-w-3xl">
        <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="changeStep($event)">
            <mat-step *ngFor="let step of this.steps" [completed]="step.completed" [editable]="step.isEditable">
                <ng-template matStepLabel>
                    {{step.title}}
                </ng-template>
            </mat-step>
            <ng-template matStepperIcon="edit">
                <mat-icon>done</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
</div>