import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { ConfigurationService } from '../core';

@Injectable({
	providedIn: 'root',
})
export class BandwidthService {
	constructor(
		protected configService: ConfigurationService,
		protected httpClient: HttpClient,
	) {}

	protected products;
	protected config;

	public getBandwidthPricing() {
		return new Observable((observer) => {
			forkJoin({
				config: this.getBandwidthConfig(),
				products: this.getProducts(),
			}).subscribe({
				next: (result) => {
					observer.next(result);
					observer.complete();
				},
				error: (error) => {
					observer.error(error);
				},
			});
		});
	}

	public getProductSpecs(product) {
		const core = product.hardwareTypes[0]?.processor?.raw_cores
			? `${product.hardwareTypes[0]?.processor?.raw_cores} Core | `
			: '';
		const ram = product.hardwareTypes[0]?.ram?.raw
			? `${product.hardwareTypes[0]?.ram?.raw} GB RAM | `
			: '';
		const storage = this.getProductStorage(product);
		const specs = core + ram + storage;
		return specs;
	}

	public getProductStorage(product) {
		const storage = product.hardwareTypes[0]?.storage;

		let storage_type, storage_size;
		if (storage.hdd?.raw) {
			storage_type = 'HDD';
			storage_size = storage.hdd?.raw;
		} else if (storage.sata_ssd?.raw) {
			storage_type = 'SSD';
			storage_size = storage.sata_ssd?.raw;
		} else if (storage.nvme_ssd?.raw) {
			storage_type = 'NVMe';
			storage_size = storage.nvme_ssd?.raw;
		}
		if (!storage_type || !storage_size) return '';

		return storage_size > 1000
			? `${storage_size / 1000} TB ${storage_type}`
			: `${storage_size} GB ${storage_type}`;
	}

	public convertTBtoMbpsPerMonth(bandwidth_TB, opts = null) {
		const secondsInMonth = 60 * 60 * 24 * 30;
		const percentUsageInPeak = (opts?.percentInPeak || 70) / 100; // 70% default
		const hoursInPeakPerDay = opts?.peakDurationHours || 8; // 8 hours default
		const secondsInPeakPerMonth = secondsInMonth / (24 / hoursInPeakPerDay);
		const TBInPeak = percentUsageInPeak * bandwidth_TB;
		const mbits = TBInPeak * 8 * 1000 * 1000;
		const mbitsConsumed = mbits / secondsInPeakPerMonth;
		return this.toFixedNumber(mbitsConsumed, 3);
	}

	public calculateBandwidthCost(total_Mbps, totalTB, products) {
		const trafficPatternConversionCoefficient = totalTB / total_Mbps;

		const allotment_Mbps = this.getBandwidthAllotment(products);
		const bandwidth_allotment_TB = this.toFixedNumber(
			allotment_Mbps * trafficPatternConversionCoefficient,
			3,
		);

		const billable_usage_TB = totalTB - bandwidth_allotment_TB;
		const billable_usage_Mbps = total_Mbps - allotment_Mbps;

		const bandwidthCostCoefficient = this.getBandwidthCoefficient(totalTB);
		const providerCost =
			billable_usage_Mbps > 0
				? this.config?.base_costs.provider_mbps * billable_usage_Mbps
				: 0;
		const teamAndHwCost =
			billable_usage_TB > 0
				? this.config?.base_costs.team_hardware_mbps *
				  billable_usage_TB *
				  1000
				: 0;
		const total_cost =
			billable_usage_Mbps > 0
				? (providerCost + teamAndHwCost) * bandwidthCostCoefficient
				: 0;
		return {
			total_Mbps: total_Mbps,
			billable_usage_Mbps:
				billable_usage_Mbps > 0 ? billable_usage_Mbps : 0,
			total_cost: total_cost,
			totalTB: totalTB,
			billable_usage_TB: billable_usage_TB > 0 ? billable_usage_TB : 0,
			bandwidth_allotment_TB,
			allotment_Mbps,
			bandwidthCostCoefficient: bandwidthCostCoefficient,
			providerCost: providerCost,
			teamAndHwCost: teamAndHwCost,
			unit_cost: total_cost / billable_usage_Mbps,
			provider_base_cost: this.config?.base_costs.provider_mbps,
			team_hw_base_cost: this.config?.base_costs.team_hardware_mbps,
		};
	}

	public getBandwidthAllotment(products) {
		const bandwidthAllotment = products.reduce((total, product) => {
			total += product.quantity
				? product.bandwidth?.egress_allotment * product.quantity
				: 0;
			return total;
		}, 0);
		return bandwidthAllotment;
	}

	public getBandwidthUnitCost(totalTB) {
		let unitCost = this.config?.bandwidth_tiers?.tier1?.mbps_cost;
		Object.values(this.config?.bandwidth_tiers)?.forEach((tier: any) => {
			const min_usage_TB = tier.min_usage;
			if (totalTB >= min_usage_TB) {
				unitCost = tier.mbps_cost;
			}
		});
		return unitCost;
	}

	public getBandwidthCoefficient(totalTB) {
		let coefficient = this.config?.bandwidth_tiers?.tier1?.cost_coefficient;
		Object.values(this.config?.bandwidth_tiers)?.forEach((tier: any) => {
			const min_usage_TB = tier.min_usage;
			if (totalTB >= min_usage_TB) {
				coefficient = tier.cost_coefficient;
			}
		});
		return coefficient;
	}

	private getBandwidthConfig() {
		return new Observable((observer) => {
			if (this.config) {
				observer.next(this.config);
				observer.complete();
			} else {
				const url =
					this.configService.config.host +
					'/v1/products/bandwidth/config';

				this.httpClient.get(url, {}).subscribe({
					error: (error) => {
						observer.error(error);
					},
					next: (config: any) => {
						this.config = config;
						observer.next(config);
						observer.complete();
					},
				});
			}
		});
	}

	private getProducts(location = 'va') {
		return new Observable((observer) => {
			if (this.products) {
				observer.next(this.products);
				observer.complete();
			} else {
				const url =
					this.configService.config.host +
					'/v1/products/addons?loc=' +
					location;
				this.httpClient.get(url, {}).subscribe({
					next: (products) => {
						this.products = products;
						observer.next(products);
						observer.complete();
					},
					error: (error) => {
						observer.error(error);
					},
				});
			}
		});
	}

	protected toFixedNumber(num, digits, base = 10) {
		const pow = Math.pow(base, digits);
		return Math.round(num * pow) / pow;
	}
}
