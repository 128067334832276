import { Route } from '@angular/router';
import {
	AmpComponent,
	CheckoutComponent
} from './index';

export const AmpRoutes: Route[] = [
	{
		path: 'amp',
		component: AmpComponent,
		pathMatch: 'prefix',
		children: [
			{
				path: 'checkout',
				component: CheckoutComponent,
			},
			{
				path: 'checkout/:products',
				component: CheckoutComponent,
			},
		],
	},
];
