import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from '@angular/common/http';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { ApiService, ProfileService, SocketService } from '@central/ng-shared';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import _isUndefined from 'lodash/isUndefined';
import { CreateSnapshotDialogComponent } from './create-snapshot-dialog/create-snapshot-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Config } from '../../../../shared/config/env.config';
import { ProjectService } from '../../../project.service';
import { AppService } from 'apps/central/src/app/app.service';
import { AnsibleService } from '../../services/ansible.service';
export interface Snapshot {
	description: string;
	date: string;
}

@Component({
	selector: 'central-snapshots',
	templateUrl: './snapshots.component.html',
	styleUrls: [
		'./snapshots.component.scss',
	],
})
export class SnapshotsComponent implements OnInit, OnDestroy {
	@ViewChild(MatTable) table: MatTable<Snapshot>;

	public state = 'loading';
	public available = true;

	displayedColumns: string[] = ['description', 'date'];
	apiUrl = (Config.hostVzManager || Config.host) + '/v1/vps/snapshots';
	veid: string;
	dataSource = {};
	action = '';
	snapshotBusy = false;

	private subscriptions = [];

	constructor(
		public socketService: SocketService,
		private apiService: ApiService,
		private appService: AppService,
		private httpClient: HttpClient,
		private projectService: ProjectService,
		private dialog: MatDialog,
		private ansibleService: AnsibleService,
		private profileService: ProfileService,
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.subscriptions.push(
                    this.projectService.getServerInfo.subscribe((info) => {
                        // TODO: Disallow functionality if container is stopped.
                        this.subscriptions.push(
                            this.projectService.getEnvironmentState.subscribe((state) => {
                                if (state !== 'pending') {
                                    this.subscriptions.push(
                                        this.ansibleService.playbookStatus.subscribe((data) => {
                                            if (data === 'pending' || data === 'complete' || data === 'playbook-overridden') {
                                                this.available = true;
                                            } else {
                                                // A playbook is running
                                                if (this.available === true) {
                                                    this.state = 'loading';
                                                }
                                                this.available = false;
                                            }
                                            this.veid =
                                                    this.projectService.environment.fields?.machine_details?.veid;
                                            if (!this.veid) {
                                                this.projectService.reloadProject().subscribe(() => {
                                                    this.veid =
                                                        this.projectService.environment.fields?.machine_details?.veid;
                                                });
                                            }

                                            if (this.state === 'loading') {
                                                this.getSnapshots(this.veid);
                                            }

                                            const events = [
                                                'snapshot-switchComplete',
                                                'snapshot-deleteComplete',
                                                'createComplete',
                                            ];

                                            events.forEach((event) => {
                                                this.socketService
                                                    .getSocket()
                                                    .on(event, (message) => this.callComplete(message));
                                            });
                                        })
                                    )
                                }
                            })
                        )
                    })
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	/**
	 *
	 * @param message Called
	 */
	callComplete(message) {
		this.getSnapshots(this.veid, message);
	}

	/**
	 * Get List of Snapshots
	 * @param veid VPS ID to get snapshots of.
	 * @returns
	 */
	getSnapshots(veid, message?: any) {
		this.httpClient
			.get(this.apiUrl, {
				params: {
					veid: veid,
				},
				headers: this.apiService.getHeaders({
					contentType: 'application/json',
				}),
			})
			.subscribe(
				(response: any) => {
					if (response.error) {
						this.state = 'failed';
					} else {
						this.state = 'success';
						this.dataSource = this.format(response);
						this.snapshotBusy = false;

						if (!_isUndefined(message)) {
							this.confirmationMessage(message);
						}
					}
				},
				(e: HttpErrorResponse) => {
					this.state = 'failed';
				}
			);
	}

	/**
	 * Displays snackbar message to confirm user action
	 * is complete after loader goes away.
	 *
	 * @param message
	 */
	confirmationMessage(message) {
		let msg = '';
		if ('snapshot-switchComplete' === message.name) {
			msg = 'Snapshot has been restored!';
		} else if ('snapshot-deleteComplete' === message.name) {
			msg = 'Snapshot has been deleted!';
		} else if ('createComplete' === message.name) {
			msg = 'New snapshot has been created!';
		}

		this.appService.showConfirmation(msg);
	}

	/**
	 * Delete snapshot from list.
	 */
	deleteSnapshot(e) {
		this.action = 'Removing';
		// snapid param is stored as data attribute.
		const snapshotId = e.target.closest('tr').dataset.snapshot;

		const options = {
			headers: this.apiService.getHeaders({
				contentType: 'application/json',
			}),
			body: {
				veid: this.veid,
				snapid: snapshotId,
			},
		};

		// Make API call to delete
		this.httpClient.delete(this.apiUrl, options).subscribe(
			(response) => {
				this.snapshotBusy = true;
			},
			(error: HttpErrorResponse) => {
				this.state = 'failed';
			}
		);
	}

	/**
	 * Use snapshot from list.
	 */
	useSnapshot(e) {
		this.action = 'Restoring';
		const snapshotId = e.target.closest('tr').dataset.snapshot;
		const options = {
			headers: this.apiService.getHeaders({
				contentType: 'application/json',
			}),
		};

		const body = {
			veid: this.veid,
			snapid: snapshotId,
		};

		this.httpClient.patch(this.apiUrl, body, options).subscribe(
			(response) => {
				this.snapshotBusy = true;
			},
			(error: HttpErrorResponse) => {
				this.state = 'failed';
			}
		);
	}

	/**
	 * Create new snapshot and add to list.
	 */
	newSnapshot(desc) {
		this.action = 'Creating';
		this.httpClient
			.post(
				this.apiUrl,
				{
					veid: this.veid,
					desc: desc,
				},
				{
					headers: this.apiService.getHeaders({
						contentType: 'application/json',
					}),
				}
			)
			.subscribe(
				(response) => {
					this.snapshotBusy = true;
				},
				(e: HttpErrorResponse) => {
					this.state = 'failed';
				}
			);
	}

	public openSnapshotDialog() {
		// TODO: Convert to this.appService.openDialog
		// Extend /shared/dialog as necessary
		// Update documentation as necessary
		// Delete this specific dialog component and references
		const dialogRef = this.dialog.open(CreateSnapshotDialogComponent, {
			disableClose: true,
			width: '500px',
		});
		dialogRef.afterClosed().subscribe((result) => {
			if ('cancel' !== result) {
				if ('' === result) {
					// Set a default name if user doesn't enter one so interface doesn't look weird.
					result = 'Untitled';
				}

				this.newSnapshot(result);
			}
		});
	}
	/**
	 * Format Snapshot response to be consumed by table.
	 *
	 * @param snapshotData result of API call to snapshot-list
	 * @returns object formatted to be consumed by angular table for display.
	 */
	format(snapshotData: object): object {
		// Insert snapshot ID into object.
		const data = [];
		for (const key in snapshotData) {
			if (snapshotData.hasOwnProperty(key)) {
				const value = snapshotData[key],
				obj = {
					id: key,
					date: value.date,
					description: value.desc,
				};

				data.push(obj);
			}
		}

		// Sort results by datetime(oldest to newest) for display.
		data.sort((a, b) => {
			const dateA = new Date(a.date).valueOf();
			const dateB = new Date(b.date).valueOf();
			return dateA - dateB;
		});

		return data;
	}
}
