import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BandwidthService } from '../bandwidth.service';

@Component({
	selector: 'central-bandwidth-calculator',
	templateUrl: './bandwidth-calculator.component.html',
	styleUrls: ['./bandwidth-calculator.component.scss'],
})
export class BandwidthCalculatorComponent implements OnInit {
	constructor(
		public httpClient: HttpClient,
		public bandwidthService: BandwidthService
	) {}

	public bandwidthFormGroup = new FormGroup({
		egressUsage: new FormControl(2, [Validators.required]),
		percentInPeak: new FormControl(70, [Validators.required]),
		peakDurationHours: new FormControl(8, [Validators.required]),
	});

	public selectedProductsFormGroup = new FormGroup({
		cloud_core: new FormControl(null, [Validators.required]),
		addons: new FormControl(null),
	});

	public costPerMbps = 0;
	public costPerGB = 0;
	public bandwidthAllotment = 0;
	public bandwidthAllotmentTB = 0;
	public allotmentMbps = 0;
	public allotmentUnit = 'Mbps';
	public bandwidthCost = 0;
	public convertedMbps = 0;
	public billable_usage_TB = 0;
	public billable_usage_Mbps = 0;
	public config;
	public products;
	public state = 'pending';

	public fakeModel;
	ngOnInit(): void {
		this.loadPricingData();
	}

	private loadPricingData() {
		this.state = 'submitted';
		this.bandwidthService.getBandwidthPricing().subscribe({
			next: (result) => {
				this.config = result['config'];
				this.products = this.filterAllowedProducts(
					result['products'],
					this.config.calculator_products
				);
				this.initSelectedProductsGroup();
				this.calculateBandwidthCost();
				this.state = 'success';
			},
			error: (error) => {
				this.state = 'error';
			},
		});
	}

	private sortByOrder(a, b) {
		return a.order > b.order;
	}

	private filterAllowedProducts(products, allowed_products) {
		return (
			products?.filter((product) =>
				allowed_products?.find(
					(allowed_product) =>
						allowed_product.add_on_code === product.add_on_code
				)
			) ?? []
		)
			.map((product) => {
				const { order } = allowed_products.find(
					(allowed_product) =>
						allowed_product.add_on_code === product.add_on_code
				);
				return {
					...product,
					order: order,
				};
			})
			.sort(this.sortByOrder);
	}

	private initSelectedProductsGroup() {
		const defaultCloudCore = this.products.find(
			(product) => product.add_on_code === this.config.default_cloud_core
		);
		defaultCloudCore['quantity'] = defaultCloudCore.cluster_count ?? 3;
		const addons = this.products
			?.filter((product) => !product.capabilities.includes('control'))
			.map((product: any) => ({
				...product,
				quantity: 0,
			}));

		this.selectedProductsFormGroup.controls.addons.setValue(addons);
		this.selectedProductsFormGroup.controls.cloud_core.setValue(
			defaultCloudCore
		);

		this.selectedProductsFormGroup.valueChanges.subscribe((value) => {
			this.calculateBandwidthCost();
		});

		this.bandwidthFormGroup.valueChanges.subscribe((value) => {
			this.calculateBandwidthCost();
		});
	}

	public calculateBandwidthCost() {
		const selected_products = [
			this.selectedProductsFormGroup.value.cloud_core,
			...this.selectedProductsFormGroup.value.addons.filter((addon) => addon.quantity > 0),
		];
		const bandwidth_TB = this.bandwidthFormGroup.controls.egressUsage.value ?? 0;
		const opts = {
			percentInPeak: this.bandwidthFormGroup.value.percentInPeak,
			peakDurationHours: this.bandwidthFormGroup.value.peakDurationHours,
		}
		const peak_bandwidth_Mbps = this.bandwidthService.convertTBtoMbpsPerMonth(bandwidth_TB, opts);
		this.convertedMbps = peak_bandwidth_Mbps;

		const calculations = this.bandwidthService.calculateBandwidthCost(peak_bandwidth_Mbps, bandwidth_TB, selected_products);

		this.bandwidthAllotment = calculations.allotment_Mbps;
		this.bandwidthAllotmentTB = calculations.bandwidth_allotment_TB;
		this.bandwidthCost = this.roundUpToDecimal(calculations.total_cost, 2);
		this.billable_usage_TB = calculations.billable_usage_TB;
		this.billable_usage_Mbps = calculations.billable_usage_Mbps;
		this.costPerMbps = calculations.total_cost > 0 ? ( calculations.total_cost / this.billable_usage_Mbps ) : 0;
		this.costPerGB = this.roundUpToDecimal(calculations.total_cost > 0 ? calculations.total_cost / (bandwidth_TB * 1000) : 0, 4);
	}

	public getUsagePercentage() {
		const usage = this.bandwidthFormGroup.controls.egressUsage.value;
		const allotment = this.bandwidthAllotment;

		return (allotment / usage) * 100;
	}

	public getAllowancePercentage() {
		const usage = this.bandwidthFormGroup.controls.egressUsage.value;
		const allotment = this.bandwidthAllotment;

		return (usage / allotment) * 100;
	}

	public roundUpToDecimal(value, digits) {
        return Math.ceil(value * (10 ** digits)) / (10 ** digits);
	}
}
