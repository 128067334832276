<div class="ctw-w-full ctw-flex ctw-justify-center">
	<div class="ctw-w-full ctw-max-w-5xl">
		<dash-card disabled="1" cardTitle="Domain" icon="domain">
			<div class="ctw-p-8">
				<h4>Let's choose your domain name.</h4>
				<mat-radio-group aria-label="Let's choose your domain name." [(ngModel)]="this.domainSelectionType"
					(change)="handleDomainType()" class="ctw-flex ctw-items-start ctw-flex-wrap">
					<mat-radio-button class="ctw-mr-5" value="new">
						Purchase new domain
					</mat-radio-button>
					<mat-radio-button class="ctw-mr-5" value="existing">
						Use my existing domain
					</mat-radio-button>
				</mat-radio-group>
			</div>
			<form>
				<div class="animate__animated animate__fadeIn ctw-px-8 ctw-flex ctw-flex-wrap">
					<mat-form-field class="ctw-w-full">
						<input matInput autocomplete="off" type="text" name="domainName" [(ngModel)]="this.domainName"
							[formControl]="domainValidator" placeholder="Enter your domain"
							(keyup)="domainChange($event)" />
						<mat-error *ngIf="domainError" [innerHTML]="domainError"></mat-error>
					</mat-form-field>

				</div>
			</form>
			<div class="ctw-flex ctw-justify-end ctw-px-8 ctw-pb-8 animate__animated animate__fadeIn">
				<div class="ctw-flex ctw-flex-wrap ctw-justify-end ctw-mt-3 sm:ctw-mt-0 ctw-w-full">
					<div class="ctw-w-full sm:ctw-w-min">
						<button class="ctw-w-full sm:ctw-w-min" mat-button mat-stroked-button mat-ripple color="primary" (click)="setDomainSelection(true)"
							[disabled]="this.cartRefId.length === 0">
							Choose my domain later
						</button>
					</div>
					<div *ngIf="domainSelectionType === 'existing'" class="sm:ctw-ml-3 ctw-mt-3 sm:ctw-mt-0 ctw-w-full sm:ctw-w-min">
						<button mat-button mat-raised-button mat-ripple type="button" color="primary"
							[disabled]="!domainValid" (click)="setDomainSelection()">
							Continue
						</button>
					</div>
					<div *ngIf="domainSelectionType === 'new' && this.allowedDomains.length === 0" class="sm:ctw-ml-3 ctw-mt-3 sm:ctw-mt-0 ctw-w-full sm:ctw-w-min">
						<central-progress-button type="raised" [state]="domainSearchLoading ? 'submitted' : 'pending'"
							color="primary" (click)="updateDomainAvailability()" [disabled]="!domainValid"
							class="animate__animated animate__fadeIn amp-domain ctw-flex ctw-w-full sm:ctw-w-min">Search Domains
						</central-progress-button>
					</div>
				</div>
			</div>
			<div class="animate__animated animate__fadeIn">
				<div *ngIf="'available' !== domainAvailability && this.allowedDomains.length"
					class="ctw-flex ctw-justify-center">
					<div class="ctw-flex ctw-flex-col ctw-w-full ctw-p-8 ctw-pr-4 sm:ctw-pr-8 ctw-pt-1 ctw-text-lg">
						<div *ngIf="this.searchedDomainUnavailable" class="ctw-text-center ctw-pb-8">>
							<span class="ctw-font-bold ctw-text-red-700">
								{{this.domainName}} is taken.
							</span> How about one of these relevant domain names:
						</div>
						<div *ngIf="this.searchedDomainUnavailable === false">
							<ng-container *ngFor="let tld of this.searchedDomain; index as i">
								<div id="tld{{i}}" class="ctw-grid sm:ctw-grid-cols-5 ctw-grid-cols-2"
									*ngIf="tld.searchedDomain">
									<div class="ctw-col-span-3 ctw-flex ctw-flex-col ctw-justify-center">{{tld.domainName}}
									</div>
									<div class="ctw-col-span-full sm:ctw-col-span-2 ctw-grid ctw-grid-cols-2">
										<div class="ctw-flex ctw-flex-col ctw-justify-center">
											<div class="ctw-grid ctw-grid-cols-3">
												<span [class]="getPriceClass(tld)" class="ctw-col-span-2">${{tld.price}} /yr</span>
												<span *ngIf="tld.freeEligible && freeDomain"
													class="ctw-text-xs ctw-font-bold ctw-bg-green-200 ctw-rounded ctw-m-1 ctw-h-5 ctw-w-10 ctw-p-0.5 ctw-text-green-700 ctw-text-center ">FREE</span>
											</div>
										</div>
										<div class="ctw-flex ctw-justify-end" *ngIf="tld.availability === 'available'">
											<button class="ctw-font-bold" mat-button color="primary"
												(click)="this.select(i, true)">
												Add & Continue
											</button>
										</div>
										<div class="ctw-flex ctw-justify-end ctw-mr-7" *ngIf="tld.availability === 'taken'">
											<button class="ctw-text-red-700" mat-button color="warn" disabled>
												Unavailable
											</button>
										</div>
									</div>
								</div>
								<hr class="sm:ctw-hidden ctw-pt-2 ctw-pb-2" />
							</ng-container>
						</div>
						<ng-container *ngFor="let tld of this.allowedDomains; index as i">
							<div id="tld{{i}}" class="ctw-grid sm:ctw-grid-cols-5 ctw-grid-cols-2"
								*ngIf="!tld.searchedDomain">
								<div class="ctw-col-span-3 ctw-flex ctw-flex-col ctw-justify-center">{{tld.domainName}}
								</div>
								<div class="ctw-col-span-full sm:ctw-col-span-2 ctw-grid ctw-grid-cols-2">
									<div class="ctw-flex ctw-flex-col ctw-justify-center">
										<div class="ctw-grid ctw-grid-cols-3">
											<span [class]="getPriceClass(tld)" class="ctw-col-span-2">${{tld.price}} /yr</span>
											<span *ngIf="tld.freeEligible && freeDomain"
												class="ctw-text-xs ctw-font-bold ctw-bg-green-200 ctw-rounded ctw-m-1 ctw-h-5 ctw-w-10 ctw-p-0.5 ctw-text-green-700 ctw-text-center ">FREE</span>
										</div>
									</div>
									<div class="ctw-flex ctw-justify-end" *ngIf="tld.availability === 'available'">
										<button class="ctw-font-bold" mat-button color="primary"
											(click)="this.select(i)">
											Add & Continue
										</button>
									</div>
									<div class="ctw-flex ctw-justify-end ctw-mr-7" *ngIf="tld.availability === 'taken'">
										<button class="ctw-text-red-700" mat-button color="warn" disabled>
											Unavailable
										</button>
									</div>
								</div>
							</div>
							<hr class="sm:ctw-hidden ctw-mt-2 ctw-mb-2" />
						</ng-container>
					</div>
				</div>
			</div>
		</dash-card>
		<div class="ctw-w-full ctw-flex ctw-justify-center">

		</div>
	</div>
</div>
