import { environment as defaultConfig } from './base';

export const environment = {
	...defaultConfig,
	...{
		host: 'https://pdt-wp-api.inmotionhosting.com',
		includeTracking: false,
		environment: 'staging',

		semaphoreUrl: 'https://pdt-wp-api.inmotionhosting.com/semaphore',

		features: {
			...defaultConfig.features,
		},

		signInOptions: ['github', 'google'],
	},
};
