import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { Format as ApiFormat } from '../../core/api/formatter';
import { catchError, delay, map, timeout } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';
import { DecoderService } from '../jwt/decoder';

@Injectable()
export class ApiService {
	public defaultTimeout = 45000;
	public timeout;
	public testCallDelay = 1500;
	public formatter: ApiFormat;
	public trackError: ReplaySubject<any> = new ReplaySubject();
	public trackError$ = this.trackError.asObservable();
	private silentLogout: ReplaySubject<boolean> = new ReplaySubject(1);
	public silentLogout$ = this.silentLogout.asObservable();
	constructor(
		public configService: ConfigurationService,
		public http: HttpClient,
		public router: Router,
		public jwt: DecoderService,
		injector: Injector
	) {
		this.formatter = new ApiFormat(this.configService.config);
	}

	/**
	 * Get the headers for each request.
	 *
	 * @since 1.5.0
	 *
	 * @param        options Header options.
	 */
	public getHeaders(options?: object): HttpHeaders {
		let headers = new HttpHeaders();
		let authenticationToken = '';

		if (!options || !options['contentType']) {
			headers = headers.append(
				'Content-Type',
				'application/x-www-form-urlencoded'
			);
		}

		// Set the authentication token if passed as option.
		if (options && options['authenticationToken']) {
			authenticationToken = options['authenticationToken'];

			// If valid token set in local storage, pull that instead.
		} else if (this.jwt.validToken()) {
			authenticationToken = this.jwt.getToken();
		}

		// Set the authentication token if available.
		if (authenticationToken) {
			headers = headers.append(
				'Authorization',
				'Bearer ' + authenticationToken
			);
		}

		if (options && options['xOrgId']) {
			headers = headers.append('X-Organization-Id', options['xOrgId']);
		}

		if (options && options['multifactor']) {
			headers = headers.append(
				'X-Multifactor-Code',
				options['multifactor']['code']
			);
			headers = headers.append(
				'X-Multifactor-Remember-Device',
				options['multifactor']['remember'] ? '1' : '0'
			);
		}

		return headers;
	}

	public getPublicError(error) {
		let message =
			'An unexpected failure occurred while processing your request. Please try again.';
		const errors = error?.error?.errors || error.errors;
		if (errors && errors[0] && errors[0].message) {
			message = errors[0].message;
		}

		return message;
	}

	public getViewException(error) {
		let exception = {};
		const errors = error?.error?.errors || error.errors;
		if (errors && errors[0] && errors[0].message && errors[0].name) {
			exception = errors[0];
		}

		return exception;
	}

	public redirectInvalidAuth(responseJson: any): void {
		if (responseJson) {
			if (
				401 === responseJson['status'] ||
				403 === responseJson['status']
			) {
				if (
					'Invalid Captcha.' === responseJson['message'] ||
					'Two-factor required.' === responseJson?.error?.message
				) {
					return;
				}

				const exception = this.getViewException(responseJson);
				if ((
					// They passed a token and the API told them it was invalid.
					'token_invalid' === exception['name'] ||
					401 === responseJson['status'] ||

					// They don't have a valid local token and the API is protected (these tokens don't get passed to the backend).
					!this.jwt.validToken()) &&

					// Do not try to logout people who are already on the logout page.
					// Or /, which would be requests before routing is established.
					! ['/', '/logout' ].includes(this.router.url) &&

					// They are a guest attempting to signup
					(!this.router.url.includes('/guide/project') &&
					!this.router.url.includes('/domains/search'))
				) {
					console.log( 'Auto Logout - Unauthenticated API Response.' );

					// user is on an unauthenticated route -- stored in a config file.
					if ( this.routePrefixIsUnauthenticated() ) {
						/**
						 * Silent logout, they do not need to be redirected to login.
						 * Without this code, unauthenticated pages would either
						 * redirect to login or have failed http requests. Refreshing the page
						 * fixes issues with any 403s on the page.
						 */
						this.silentLogout.next(true);
						location.reload();
					} else {
						this.router.navigate(['/logout'], {
							skipLocationChange: true,
						});
					}
				}
			}
		}
	}

	private routePrefixIsUnauthenticated() {
		const prefixes = this.configService.config.unauthenticatedRoutePrefixes || [];
		const isUnauthenticated = prefixes.some(prefix => this.router.url.startsWith(prefix));
		return isUnauthenticated;
	}

	public logoutUnauth(res: object) {
		this.redirectInvalidAuth(res);
		return res;
	}

	/**
	 * Log an error.
	 *
	 * @since 1.11.0
	 *
	 * @param  error   HttpResponse Reponse object.
	 * @param  payload Request Payload.
	 */
	public logError(error: HttpResponse<any>, payload: object) {
		// When an API call timeosuts, log it.
		if ('TimeoutError' === error['name']) {
			this.trackError.next( {
				category: 'api',
				name: 'timeout',
				data: payload['url'],
		});

			// If an API calls status is anything other than unauthorized, log it.
		} else if (-1 === [403, 401].indexOf(error.status)) {
			this.trackError.next({
				category: 'api',
				name: 'response_error',
				value: error.status,
				data: error.url || payload['url'],
			});
		}
	}

	/**
	 * Actions to occur on an unsuccessful status code from the API.
	 *
	 * @since 1.11.0
	 *
	 * @param  error   Error object.
	 * @param  payload Request data.
	 * @return         Observable Response Error.
	 */
	public handleServerError(error: HttpResponse<any>, payload: object) {
		this.redirectInvalidAuth(error);
		this.logError(error, payload);

		return throwError(error['error'] || error);
	}

	public get(name: string, data: object, timeout: number = undefined): Observable<object> {
		this.timeout = timeout || this.defaultTimeout;
		return this.baseCall(name, data, 'get');
	}

	public post(name: string, data: object, timeout: number = undefined): Observable<object> {
		this.timeout = timeout || this.defaultTimeout;
		return this.baseCall(name, data, 'post');
	}

	public put(name: string, data: object, timeout: number = undefined): Observable<object> {
		this.timeout = timeout || this.defaultTimeout;
		return this.baseCall(name, data, 'put');
	}

	public patch(name: string, data: object, timeout: number = undefined): Observable<object> {
		this.timeout = timeout || this.defaultTimeout;
		return this.baseCall(name, data, 'patch');
	}

	public delete(name: string, data: object, timeout: number = undefined): Observable<object> {
		this.timeout = timeout || this.defaultTimeout;
		return this.baseCall(name, data, 'delete');
	}

	public upload(name: string, data: any, timeout: number = undefined): Observable<object> {
		this.timeout = timeout || this.defaultTimeout;
		const body = data;
		const promise = this.http.post(this.formatter.getUrl(name), body, {
			headers: this.getHeaders({ contentType: 'default' }),
		});

		return this.mapper(name, data, promise);
	}

	public mapper(
		name: string,
		data: any,
		observable: Observable<object>
	): Observable<object> {
		if (false === this.configService.config.useMocks) {
			return observable.pipe(
				timeout(this.timeout),
				map((res) => this.logoutUnauth(res)),
				catchError((res) =>
					this.handleServerError(res, {
						url: this.formatter.getUrl(name),
					})
				)
			);
		} else {
			return this.mockCall(name, data);
		}
	}

	public mockCall(name: string, data: any): Observable<object> {
		return this.http
			.get(this.formatter.getUrl(name), { params: data })
			.pipe(timeout(this.timeout), delay(this.testCallDelay));
	}

	public isSuccessResponse(response: any): boolean {
		const successPassed =
			response.result &&
			response.result.data &&
			response.result.data.success;
		const successUndefined =
			response.result &&
			response.result.data &&
			response.result.data.success === undefined;
		const validResellerCall =
			response['status'] === 200 && successUndefined;

		return successPassed || validResellerCall;
	}

	public request(options: object) {
		let observer: any;

		const observable = new Observable((o: any) => {
			observer = o;
		});

		this.post(options['name'], options['params'])
			.pipe(map((response: any) => response.json()))
			.subscribe(
				(response: any) => {
					if (this.isSuccessResponse(response)) {
						observer.next(response.result.data);
						observer.complete();
					} else {
						observer.error(response.result.data);
					}
				},
				(err: any) => {
					observer.error(err);
				}
			);

		return observable;
	}

	/**
	 * Attach params to the url when needed.
	 *
	 * @since 1.3
	 *
	 * @param   apiCall API call string.
	 * @param   data    Parameters for request.
	 *
	 * @return          Updated parameters.
	 */
	private replaceRouteParams(apiCall: string, data: object): object {
		const updatedData = {};

		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key];
				const search = '::' + key;
				if (-1 !== apiCall.search(search)) {
					apiCall = apiCall.replace(search, value);
				} else {
					updatedData[key] = value;
				}
			}
		}

		return {
			apiCall,
			data: updatedData,
		};
	}

	private baseCall(
		name: string,
		data: object,
		requestName: string
	): Observable<object> {
		let promise: Observable<object>;
		let apiCall = this.formatter.getUrl(name);
		const headerOptions = data['headerOptions'] || {};

		delete data['headerOptions'];

		const updatedOptions = this.replaceRouteParams(apiCall, data);

		apiCall = updatedOptions['apiCall'];
		data = updatedOptions['data'];

		const httpParams = this.formatter.getBody(data);
		const options = {
			headers: this.getHeaders(headerOptions),
			params: httpParams,
		};

		// Pass post params into body, get into params option.
		if ('get' === requestName || 'delete' === requestName) {
			promise = this.http[requestName](apiCall, options);
		} else {
			delete options.params;
			promise = this.http[requestName](
				apiCall,
				httpParams.toString(),
				options
			);
		}

		return this.mapper(name, data, promise);
	}
}
