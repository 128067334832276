import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'central-available-products',
	templateUrl: './available-products.component.html',
	styleUrls: ['./available-products.component.scss'],
})
export class AvailableProductsComponent implements OnInit {
	constructor() {}
	@Input() public products;
	@Input() public selectedProductsFormGroup;
	public categorized_products;

	public ngOnInit() {
		this.categorized_products = this.mapProducts(this.products);
	}

	public addProduct({product, product_group}) {
		if (product_group === 'cloud_core') {
			this.selectCoreProduct(product);
		} else {
			const selectedAddonsControl = this.selectedProductsFormGroup.controls.addons;
			const incrementedProducts = selectedAddonsControl.value.map((selectedProduct) => {
				if (selectedProduct.add_on_code === product.add_on_code) {
					selectedProduct.quantity++;
				}
				return selectedProduct;
			});

			selectedAddonsControl.setValue(incrementedProducts);
		}
	}

	public selectCoreProduct(product) {
		const selectedCoreControl = this.selectedProductsFormGroup.controls.cloud_core;
		selectedCoreControl.setValue(product);
	}

	private mapProducts(products) {
		const categorized_products = {
			cloud_core: {
				label: 'Cloud Core',
				id: 'cloud_core',
				products: this.filterProductsByCapabilities(products, [
					'storage',
					'compute',
					'control',
				]).map((product) => ({
						...product,
						quantity: product.cluster_count ?? 3,
					})),
			},
			compute: {
				label: 'Compute',
				id: 'compute',
				products: this.filterProductsByCapabilities(products, [
					'compute',
				]),
			},
			converged: {
				label: 'Converged',
				id: 'converged',
				products: this.filterProductsByCapabilities(products, [
					'compute',
					'storage',
				]),
			},
			storage: {
				label: 'Storage',
				id: 'storage',
				products: this.filterProductsByCapabilities(products, [
					'storage',
				]),
			},
		};

		return categorized_products;
	}

	private filterProductsByCapabilities(products, capabilities = []) {
		return (
			products?.filter(
				(product) =>
					product.capabilities?.sort().join() ===
					capabilities?.sort().join()
			) ?? []
		);
	}
}
