import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
	ApiService,
	ProfileService,
	ConfigurationService,
} from '@central/ng-shared';
import { PublicStorageService } from '../../local-storage/public-storage.service';
import { LocalStorageService } from '@central/ng-shared';

@Component({
	selector: 'central-team-selector',
	templateUrl: './selector.component.html',
	styleUrls: ['./selector.component.scss'],
})
export class TeamSelectorComponent implements OnInit, OnDestroy {

	@Input() public manage = false;

	@Output() teamChange: EventEmitter<any> = new EventEmitter();

	public teams = [];
	public activeTeam;
	public activeTeamName = '';

	private subscriptions = [];

	constructor(
		public profileService: ProfileService,
		public configService: ConfigurationService,
		public publicStorage: PublicStorageService,
		public localStorage: LocalStorageService,
		public snackBar: MatSnackBar,
	) {
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onProfileUpdate.subscribe((data: any) => {
				this.teams = data.account_access.filter((team) => team.type === 'organization');
				this.activeTeam = this.teams.find((team) => team.account_id === data.options?.public_selected_team)
					|| this.teams[0]
				this.activeTeamName = this.activeTeam?.display_name;
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	setTeam(team) {
		this.teamChange.emit(this.manage ? team.account_id : 'pending');
		this.profileService.update({
			public_selected_team: team.account_id,
		}).subscribe({
			complete: () => {
				this.teamChange.emit('complete');
				this.snackBar.open(`You are now working inside of ${this.activeTeamName}!`, '', {
					...this.configService.config.snackBar,
				});
				this.localStorage.setItem('team', this.activeTeamName);
			},
			error: (error) => {
				this.teamChange.emit('failed');
				// TODO: Push to rollbar
			},
		});
	}
}
