import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact/contact.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationModule } from '../authentication/authentication.module';
import { EmailInputComponent } from './email-input/email-input.component';
import { RecurlyCreditCardComponent } from './recurly-credit-card/recurly-credit-card.component';
import { StripeCreditCardComponent } from './stripe-credit-card/stripe-credit-card.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PhoneNumberModule } from '../phone-number';

@NgModule({
	declarations: [
		ContactComponent,
		EmailInputComponent,
		RecurlyCreditCardComponent,
		StripeCreditCardComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AuthenticationModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatAutocompleteModule,
		MatProgressBarModule,
		PhoneNumberModule
	],
	exports: [
		RecurlyCreditCardComponent,
		StripeCreditCardComponent,
		ContactComponent,
	],
})
export class BillingModule {}
