import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BandwidthCalculatorComponent } from './bandwidth-calculator/bandwidth-calculator.component';
import { SelectedProductsComponent } from './selected-products/selected-products.component';
import { AvailableProductsComponent } from './available-products/available-products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { BandwidthService } from './bandwidth.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SelectedProductDetailsComponent } from './selected-products/selected-product-details/selected-product-details.component';
import { MatRadioModule } from '@angular/material/radio';
import { ExampleScenariosComponent } from './example-scenarios/example-scenarios.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
	declarations: [
		BandwidthCalculatorComponent,
		SelectedProductsComponent,
		AvailableProductsComponent,
		ProductDetailsComponent,
		SelectedProductDetailsComponent,
		ExampleScenariosComponent,
	],
	imports: [
		CommonModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatTabsModule,
		MatCardModule,
		MatButtonModule,
		MatIconModule,
		MatRadioModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatProgressBarModule,
		MatTooltipModule,
	],
	providers: [BandwidthService],
	exports: [BandwidthCalculatorComponent],
})
export class BandwidthCalculatorModule {}
