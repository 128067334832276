import { Injectable} from '@angular/core';
import { ApiService } from '@central/ng-shared';
import { ConfigurationService } from '@central/ng-shared';
import { User, Billing, Payment} from '../models';
import { AmpLocalStorageService } from '../amp-local-storage-service';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AmpOpApiService {
	public apiUrl = '';
	public opApiUrl = '';
	public headers = {}
	public headeroptions = {}
	public ampConnectCookie;
	public states: any = [];

	public constructor(
		public apiService: ApiService,
		public configService: ConfigurationService,
		public localStorageService: AmpLocalStorageService,
		public cookieService: CookieService
	) {
		this.apiUrl = this.configService.config.powerPanel.ampHost;
		this.opApiUrl = this.configService.config.powerPanel.opHost;
		this.headeroptions = {
			'Content-Type':'application/json; charset=utf-8'
		}
		this.ampConnectCookie = this.cookieService.get('AmpConnect');
	}

	/**
	 * Get Product Data Feed from IMHOP.
	 *
	 * Store data feed in local storage.
	 *
	 * @param  packages Package and Product Info.
	 * @param  params   Affiliate and/or campaign info.
	 */
    public checkDataFeed(packages, params = {}) {
		return new Observable((observer) => {
			const opParams = {};
			if(packages) {
				const b = packages.split('-')
				const ProductName		= b[0];
				const PackageId = parseInt(b[1],10);

				opParams['packageId'] = PackageId;
				opParams['productName'] = ProductName;
				opParams['includeAddons'] = true;
					

				if(params['campaign']) {
					opParams['campaignHandle'] = params['campaign'];
				}
				
				const dataFeed = this.getStoredDataFeed();
				let updateDataFeed = true;
				if (dataFeed) {
					const sameParams = this.sameDataFeedParams(opParams);
					if(sameParams === true && (moment().toISOString() < moment(dataFeed.ttl).toISOString())) {
						const data = this.filterPackages(packages);
						if(data.packageFound === true) {
							updateDataFeed = false;
							observer.next(data);
							observer.complete();
						}
					}
				}
				if(updateDataFeed) {
					const url = this.opApiUrl + '/api/product-catalog/data-feed';

					this.apiService.http.post(url, opParams, this.headeroptions)
						.subscribe( 
							(catalog) => {
								this.setStoredDataFeed(catalog)
								this.setDataFeedParams(opParams)
								const data = this.filterPackages(packages);
								observer.next(data);
								observer.complete();
							},
							(resp) => {
								observer.next('Unable to get data feed');
								observer.error();
							}
						);
				}
			} else {
				observer.next('Unable to get data feed');
				observer.error();
			}
		});
		
	}

	/**
	 * Get Domain Data Feed from IMHOP.
	 *
	 * Store data feed in local storage.
	 *
	 * @param  packages Package and Product Info.
	 * @param  params   Affiliate and/or campaign info.
	 */
	public checkDomainInfo() {
		return new Observable((observer) => {
			const domainInfo = this.getStoredDomainInfo();
			if(domainInfo.hasOwnProperty('data')) {
				observer.next(domainInfo);
				observer.complete();
			} else {
				const opParams = {};
				opParams['packageId'] = 39;
				opParams['productName'] = 'Domain Privacy';
				opParams['includeAddons'] = true;

				const url = this.opApiUrl + '/api/product-catalog/data-feed';

				this.apiService.http.post(url, opParams, this.headeroptions)
					.subscribe( 
						(catalog) => {
							this.setStoredDomainInfo(catalog);
							observer.next(catalog);
							observer.complete();
						},
						(resp) => {
							observer.next('Unable to get domain info');
							observer.error();
						}
					);
			}
		});
		
	}

	/**
	 * Get Product Data Feed from Local Storage.
	 *
	 * @return Object   Data Feed.
	 */
	public getStoredDataFeed() {
		return this.localStorageService.getItem('op-data-feed');
	}

	/**
	 * Store DataFeed in Local Storage.
	 *
	 * Set TTL for DataFeed
	 * @param catalog Data Feed.
	 */
	public setStoredDataFeed(catalog) {
		catalog.ttl = moment().add(1,'days')
		this.localStorageService.setItem('op-data-feed',catalog);

	}

	/**
	 * Get Product Data Feed from Local Storage.
	 *
	 * @return Object   Data Feed.
	 */
	public getCartCatalog() {
		const catalog = this.localStorageService.getItem('op-catalog');
		if(catalog !== undefined) {
			catalog['data'] = Object.keys(catalog.data).map((key) => catalog.data[key]);
			return catalog;
		} else {
			return {};
		}
	}

	/**
	 * Store DataFeed in Local Storage.
	 *
	 * Set TTL for DataFeed
	 * @param catalog Data Feed.
	 */
	public setCartCatalog(catalog) {
		const data = {
			data: catalog,
			ttl: moment().add(1,'days')
		}
		this.localStorageService.setItem('op-catalog',data);

	}

	/**
	 * Get Data Feed Params.
	 *
	 * @return Object   Data Feed.
	 */
	public getDataFeedParams() {
		return this.localStorageService.getItem('op-data-feed-params');
	}

	/**
	 * Store DataFeed in Local Storage.
	 *
	 * Set TTL for DataFeed
	 * @param catalog Data Feed.
	 */
	public setDataFeedParams(params) {
		this.localStorageService.setItem('op-data-feed-params',params);

	}

	/**
	 * Get Cart Info from Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @return cart info.
	 */
	public getStoredCart() {
		const cart = this.localStorageService.getItem('op-cart');
		if(cart !== undefined) {
			return cart;
		} else {
			return {};
		}
	}

	/**
	 * Store Cart in Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @param cart Cart info.
	 */
	public setStoredCart(cart) {
		cart.ttl = moment().add(90,'days')
		this.localStorageService.setItem('op-cart',cart);
		const cookieHostname = '.inmotionhosting.com';
		const ttl = new Date();
		ttl.setTime(
			ttl.getTime() + 4*60*60000 /* 4 hours in milliseconds */
		);
		this.cookieService.set('CentralOPCid', cart.Id, {
			path: '/',
			domain: cookieHostname,
			expires: ttl,
			sameSite: 'None',
			secure: true
		});
		this.cookieService.set('CentralOPCsk', cart.CustomerSessionKey, {
			path: '/',
			domain: cookieHostname,
			expires: ttl,
			sameSite: 'None',
			secure: true
		});
	}
	
	/**
	 * Get Domain Info from Local Storage.
	 *
	 * Set TTL for DomainInfo
	 * @return Domain info.
	 */
	public getStoredDomainInfo() {
		const domainInfo = this.localStorageService.getItem('op-domain');
		if(domainInfo !== undefined) {
			return domainInfo;
		} else {
			return {};
		}
	}

	/**
	 * Store DomainInfo in Local Storage.
	 *
	 * Set TTL for DomainInfo
	 * @param cart Domain info.
	 */
	public setStoredDomainInfo(domainInfo) {
		domainInfo.ttl = moment().add(90,'days')
		this.localStorageService.setItem('op-domain',domainInfo);
	}

	/**
	 * Get Account Info from Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @return cart info.
	 */
	public getStoredAccount() {
		const account = this.localStorageService.getItem('op-account');
		if(account !== undefined) {
			return account;
		} else {
			return {};
		}
	}

	/**
	 * Store Cart in Local Storage.
	 *
	 * Set TTL for CartInfo
	 * @param cart Cart info.
	 */
	public setStoredAccount(account) {
		account.ttl = moment().add(90,'days')
		this.localStorageService.setItem('op-account',account);
	}


	public setPackageInfo(packages) {
		this.localStorageService.setItem('op-package-info',packages);
	}
	public setAffiliateInfo(affiliate, campaign = null) {
		this.localStorageService.setItem('op-affiliate-info',affiliate);
		if(campaign) {
			this.localStorageService.setItem('op-campaign-info',campaign);
		}
	}

	public getPackageInfo() {
		return this.localStorageService.getItem('op-package-info');
	}

	public setStoredEvents(events) {
		this.localStorageService.setItem('op-events-info',events);
	}

	public getStoredEvents() {
		const events = this.localStorageService.getItem('op-events-info');
		if(events !== undefined) {
			return events;
		} else {
			return [];
		}
	}
	public getCampaignInfo() {
		return this.localStorageService.getItem('op-campaign-info');
	}
	public getAffiliateInfo() {
		return this.localStorageService.getItem('op-affiliate-info');
	}

	/**
	 * Delete cart/datafeed info in Local Storage.
	 *
	 */
	public clearStoredInfo() {
		this.localStorageService.removeItem('op-cart')
		this.localStorageService.removeItem('op-package-info')
		this.localStorageService.removeItem('op-campaign-info')
		this.localStorageService.removeItem('op-affiliate-info')
		this.localStorageService.removeItem('op-events-info')
		this.localStorageService.removeItem('op-account')
		this.localStorageService.removeItem('op-data-feed')
		this.localStorageService.removeItem('op-data-feed-params')
		this.localStorageService.removeItem('op-catalog')
		const cookieHostname = '.inmotionhosting.com';
		this.cookieService.delete('CentralOPCid','/',cookieHostname);
		this.cookieService.delete('CentralOPCsk','/',cookieHostname);
	}

	public clearEventsInfo() {
		this.localStorageService.removeItem('op-events-info')
	}

	/**
	 * Search data feed for package info.
	 * 
	 * @param packages package and product string.
	 */
	public filterPackages(packages) {
		const catalog = this.getStoredDataFeed();
		const data = {
			packages: [],
			terms: [],
			term: "",
			packageFound: false,
			freeDomain: false,
			requiresDomain: false
		};
		const b = packages.split('-')
		const packageHandle = {
			name: b[0].toLowerCase(),
			packageId: parseInt(b[1],10),
			productId: parseInt(b[2],10)
		}
		let selectedPackage = {}
		const selectedAddons = [];
		let selectedTerm = "";
		let info = catalog.data;
		if(catalog.data instanceof Object) {
			info = Object.keys(catalog.data)
		}
		
		for (let i = 0; i < info.length; i++) {
			const product = catalog.data[i];
			if(product) {
				if(
					product.ProductName.toLowerCase().replace('-','').includes(packageHandle.name) &&
					parseInt(product.PackageId,10) === packageHandle.packageId) {
					const catalogPackage = this.createCatalogPackage(product);
					
					if(parseInt(product.PackageToProductOfferingId,10) === packageHandle.productId){
						data.packageFound = true;
						if(catalogPackage.type === 'hosting' && catalogPackage['info'].FreeDomain === true) {
							data.freeDomain = true;
						}
						data.requiresDomain = catalogPackage['info'].PackageRequiresDomain
						selectedPackage = catalogPackage;
						catalogPackage['addons'].forEach(v => {
							if(v.AddonAutoSelected) {
								const addon = this.createCatalogPackage(v);
								addon['selected'] = true;
								selectedAddons.push(addon)
							}
						})
						selectedTerm = catalogPackage.planCode;
					}
					data.terms.push(catalogPackage)
				}
				
			}
			
		}
		data.packages.push(selectedPackage)
		data.packages = [...data.packages, ...selectedAddons];
		data.term = selectedTerm;
		return data;
	}

	public createCatalogPackage(product, domain = null) {
		const price = parseFloat(product.Price) * 100;
		let termType = product.TermType;
		if(parseInt(product.TermLength,10) > 1) {
			termType += "s";
		}
		const catalogPackage = {
			"label": product.LegacyName,
			"code": product.PackageId +'_'+product.ProductOfferingId,
			"planCode": product.PackageId +'_'+product.ProductOfferingId,
			"packageId": product.PackageId,
			"productId": product.PackageToProductOfferingId,
			"productOfferingId": product.ProductOfferingId,
			"plan_interval_length": product.TermLength,
			"plan_interval_unit": termType,
			"plan_interval_unit_display": termType.charAt(0).toUpperCase() + termType.slice(1),
			"price_in_cents": price,
			"type": product.ProductType.split(':')[0].toLowerCase(),
			"selected": false,
		}
		if(product.hasOwnProperty('AddonDetailsId')) {
			catalogPackage['productId'] = product['AddonDetailsId'];
		}
		catalogPackage['addons'] = [];
		if(product.hasOwnProperty('Addons')) {
			product.Addons = product.Addons.filter(addon => {
				if(['both','op'].includes(addon.Availability)) {
					return true;
				}
			});
			catalogPackage['addons'] = product.Addons;
		}
		catalogPackage["info"] = product;
		catalogPackage["transaction_type"] = "recurring";
		catalogPackage["quantity"] = product.hasOwnProperty('quantity') ? product['quantity'] : 1;
		catalogPackage["options"] = {
			"allows_multiple": product.AllowedQuantity > 1,
			"allows_term_discount": false
		}
		if(domain) {
			catalogPackage["options"]['domain_name'] = domain;
		}
		catalogPackage["is_recurring"] = false;
		const originalPrice = parseFloat(product.Price);
		const discountPrice = parseFloat(product.DiscountPrice);
		if(originalPrice !== discountPrice) {
			let discount = 0;
			if(discountPrice < originalPrice) {
				discount = originalPrice - discountPrice;
			}
			if(discount > 0) {
				catalogPackage['discounts'] = {
					type:"dollars",
					amount: discount
				}
			}
		}
		return catalogPackage;
	}

	/**
	 * Make api call to create op cart info.
	 * Store info in local storage.
	 * 
	 * @param  packages Package and Product Info.
	 * @param  params   Affiliate and/or campaign info.
	 */
	public createCart(packages, params) {
		const vm = this;
		return new Observable((observer) => {
			const campaignHandle = params.campaign ? params.campaign : null;
			const storedCart = this.getStoredCart();
			let cartCall;
			let irid = this.cookieService.get('irid');
			const affiliates = this.getAffiliateInfo();
			if(affiliates) {
				irid = affiliates;
			}
			const items = [];
			let url;
			const b = packages.split('-')
			const PackageId = parseInt(b[1],10);
			const ProductId = parseInt(b[2],10);

			const itemData = {
				CampaignHandle: campaignHandle,
				PackageId: PackageId,
				ProductId: ProductId
			}
			items.push(itemData);
			const cartData = {
				CampaignHandle: campaignHandle,
				ImpactRadiusId: irid,
				Items: items,
				Referrer: params.referrer
			}

			if(storedCart.hasOwnProperty('CustomerSessionKey') === false) {
				url = this.opApiUrl + '/api/cart';
				cartCall = vm.apiService.http.post(url, cartData, this.headeroptions)
				cartCall.subscribe( 
					(cart) => {
						cart.Items = cartData.Items;
						vm.setStoredCart(cart);
						this.fetchAmpCatalog().subscribe();
						observer.next(cart)
						observer.complete()
					},
					(resp) => {
						console.log(resp)
						observer.next('failed')
						observer.error()
					}
				);
			} else {
				url = this.opApiUrl + '/api/order/initialize';
				const data = {
					CustomerSessionKey: storedCart.CustomerSessionKey
				}
				cartCall = vm.apiService.http.post(url, data, this.headeroptions)
				cartCall.subscribe( 
					(cart) => {
						if(cart.success === true) {
							const cartInfo = {
								Id: cart.Id,
								RefId: cart.RefId,
								CustomerSessionKey: cart.CustomerSessionKey,
								Items: cart.Items
							}
							vm.setStoredCart(cartInfo);
							this.fetchAmpCatalog().subscribe();
							observer.next(cartInfo)
							observer.complete()
						} else {
							this.localStorageService.removeItem('op-cart')
							url = this.opApiUrl + '/api/cart';
							const newCartCall = vm.apiService.http.post(url, data, this.headeroptions)
							newCartCall.subscribe( 
								(newCart) => {
									newCart['Items'] = data['Items'];
									vm.setStoredCart(newCart);
									this.fetchAmpCatalog().subscribe();
									observer.next(newCart)
									observer.complete()
								},
								(resp) => {
									console.log(resp)
									observer.next('failed')
									observer.error()
								}
							);
						}
					},
					(resp) => {
						console.log(resp)
						observer.next('failed')
						observer.error()
					})
				this.fetchAmpCatalog().subscribe();
			}
		});
	}
	
	/**
	 * Make purchase api call to IMHOP.
	 * Build purchase request
	 * 
	 * @param  data Cart and User Info.
	 * @return  Observable.
	 */
	public purchase(data) {
		const vm = this;
		const url = this.opApiUrl + '/api/order/purchase'
		const request = this.buildPurchaseRequest(data);
		return vm.apiService.http.post(url, request, this.headeroptions)
	}

	/**.
	 * Build purchase request
	 * 
	 * @param  data Cart and User Info.
	 * @return  request object.
	 */
	private buildPurchaseRequest(data) {
		let irid = this.cookieService.get('irid');
		const affiliates = this.getAffiliateInfo();
			if(affiliates) {
				irid = affiliates;
			}
		const cart = this.getStoredCart();
		let affiliateInfo = {};
		if(irid) {
			affiliateInfo = {
				Id: irid,
				RefId: cart.RefId
			}
		}
		const request = {
			Items:              [],
			User:               <User>{},
			Billing:            <Billing>{},
			Payment:            <Payment>{},
			Affiliate:          affiliateInfo,
			//Webpro:             {
			//	Banner:    null,
			//	Id:        0,
			//	Register:  false,
			//},
			CustomerSessionKey: cart.CustomerSessionKey,
			OrderSource: 'central',
			Events:             "",
		};
		const addons = [];
		let mainItem = {};
		let domainItem = null;
		let domain = data.domainInfo.name;
		let domainPrivacy = null;
		let id = 320;
		data.items.forEach( cartItem => {
			const item = {
				Id:			id,
				ProductId:  cartItem.productId,
				Quantity:   cartItem.quantity,
			}
			if(cartItem.type === 'hosting'){
				item['PackageId'] = cartItem.packageId;
				domain = cartItem.domain;
				mainItem = item;
			}
			if(cartItem.type === 'domain') {
				item['Domain'] = cartItem.domain;
				item['Free'] = data.domainInfo.freeDomain;
				item['UseDomainCredit'] = data.domainInfo.freeDomain;
				domainItem = item;
			}
			if(cartItem.label === 'Domain Privacy') {
				domainPrivacy = item;
			}
			if(cartItem.type === 'addon' && cartItem.label !== 'Domain Privacy') {
				addons.push(item)
			}
			id++;
		} );
		if(addons.length > 0) {
			mainItem['Addon'] = addons;
		}
		if(domainItem) {
			mainItem['PrimaryDomainId'] = domainItem.Id;
			domainItem['PlanId'] = mainItem['Id'];
			if(domainPrivacy) {
				domainItem['Addon'] = [domainPrivacy];
			}
		} else {
			mainItem['OwnedDomain'] = domain;
		}
		request.Items.push(mainItem);
		if(domainItem) {
			request.Items.push(domainItem);
		}
		let fullName;
		// User
		if(data.hasOwnProperty('user')) {
			delete request.Billing;
			const phonePrefix = data.user.dialing_code;
			const phoneNumber = data.user.phone.replace("+"+phonePrefix, '')

			request.User.FirstName        = data.user.first_name;
			request.User.LastName         = data.user.last_name;
			fullName = data.user.first_name + ' ' + data.user.last_name;
			request.User.Address1         = data.user.address1;
			request.User.Address2         = data.user.address2;
			request.User.City             = data.user.city;
			request.User.Country          = data.user.country; 
			request.User.Province         = data.user.state;
			request.User.CompanyName      = data.user.company_name;
			request.User.ContactType      = '';
			request.User.ContactTypeOther = null;
			request.User.CustomerType     = data.user.customerType.toLowerCase();
			request.User.Email            = data.user.email;
			request.User.Phone            = phoneNumber;
			request.User.PhonePrefix      = phonePrefix;
			request.User.Postal           = data.user.postal_code;
			request.User.Referred         = null;
			request.User.SalesPersonId    = null;
			request.User.SalesPersonsId2  = null;
			request.User.Password		  = data.user.password;
		}
		
		// Billing
		if(data.hasOwnProperty('billing')) {
			delete request.User;
			request.Billing.FirstName        = data.billing.first_name;
			request.Billing.LastName         = data.billing.last_name;
			fullName = data.billing.first_name + ' ' + data.billing.last_name;
			request.Billing.Address1         = data.billing.address1;
			request.Billing.Address2         = data.billing.address2;
			request.Billing.City             = data.billing.city;
			request.Billing.Country          = data.billing.country;
			request.Billing.Province         = data.billing.state;
			request.Billing.CompanyName      = data.billing.company_name;
			request.Billing.Email            = data.billing.email;
			request.Billing.Phone            = data.billing.phone;
			request.Billing.Postal           = data.billing.postal_code;
		}

		// Payment
		request.Payment.Method        = data.payment.method;
		if(data.payment.method === 'Credit Card') {
			if(data.payment.hasOwnProperty('id')) {
				request.Payment.Id = data.payment.id;
			} else {
				request.Payment.Method        = data.payment.method;
				request.Payment.Csv           = data.payment.cvv;
				request.Payment.Expiry        = data.payment.expiration;
				request.Payment.Name      	  = fullName;
				request.Payment.Number        = data.payment.card_number;
			}
		}
		const events = this.getStoredEvents();
		events[0]['CustomerSessionKey'] = cart.CustomerSessionKey;
		const billingEvent = {
			updateEvent: true,
			AccountId: "",
			event: "cart:billing",
			timeStamp: Date.now()
		}
		if(request.hasOwnProperty('User')) {
			billingEvent['AccountId'] = request.User.hasOwnProperty('AccountId') ? request.User['AccountId'] : "";
			billingEvent['User'] = {...request.User};
			if(billingEvent['User'].hasOwnProperty('Password')) {
				delete billingEvent['User']['Password'];
			}
		}
		if(request.hasOwnProperty('Billing')) {
			billingEvent['Billing'] = {...request.Billing};
			if(billingEvent['Billing'].hasOwnProperty('Password')) {
				delete billingEvent['Billing']['Password'];
			}
		}
		
		events.push(billingEvent);
		request.Events = JSON.stringify(events);
		return request;
	}

	/**
	 * Make authentication api call to IMHOP.
	 * Should get cookie
	 * 
	 * @param  data Cart and User Info.
	 * @return  Observable.
	 */
	public cartAuthenticate = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				password: data.password,
			}
			this.headeroptions['withCredentials'] = true;
			const url = this.opApiUrl + '/api/user/authenticate'
			vm.apiService.http.post(url, request, this.headeroptions)
				.subscribe(resp => {
					if(resp.hasOwnProperty('authenticated')) {
						if(resp.authenticated === true) {
							if(resp.hasOwnProperty('access-token')) {
								const ttl = new Date();
								ttl.setTime(
									ttl.getTime() + 4*60*60000 /* 4 hours in milliseconds */
								);
								const cookieHostname = '.inmotionhosting.com';
								this.cookieService.set('AmpConnect', resp['access-token'], {
									path: '/',
									domain: cookieHostname,
									expires: ttl,
									sameSite: 'None',
									secure: true
								});
							}
							this.ampConnectCookie = this.cookieService.get('AmpConnect');
							observer.complete();
						} else {
							observer.error('failed');
						}
					} else {
						observer.error('failed');
					}
				})

		})
    }

	/**
	 * Make has-two-factor api call to IMHOP.
	 * 
	 * @param  data email.
	 * @return  Observable.
	 */
	public checkTwoFactor = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				CustomerSessionKey: cart.CustomerSessionKey
			}
			this.headeroptions['withCredentials'] = true;
			const url = this.opApiUrl + '/api/user/has-two-factor'
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp.hasOwnProperty('hasTwoFactor')) {
						if(['text','dormant'].includes(resp['type'])) {
							const codeRequest = {
								email: data.email,
								type: resp['type'],
								accountId: resp['accountId']
							}
							if(resp['type'] === 'dormant') {
								codeRequest['send'] = false;
								codeRequest['comment'] = true
							}
							this.sendVerificationCode(codeRequest).subscribe()
						}
						observer.next(resp)
						observer.complete();
						
					} else {
						observer.error(resp);
					}
				})

		})
    }

	/**
	 * Make verify-two-factor api call to IMHOP.
	 * 
	 * @param  data email.
	 * @return  Observable.
	 */
	public sendVerificationCode = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				accountId: data.accountId,
				CustomerSessionKey: cart.CustomerSessionKey
			}
			this.headeroptions['withCredentials'] = true;
			let path = '/api/user/send-sms-auth'
			if(data['type'] === 'dormant') {
				path = '/api/user/send-one-time-code';
			}
			const url = this.opApiUrl + path;
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp['status'] === '200') {
						observer.next(resp)
						observer.complete();
					} else {
						observer.error(resp);
					}
				})

		})
    }

	public addNewCard = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const url = this.opApiUrl + '/api/new-card/undefined'
			vm.apiService.http.post(url, data, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp['id']) {
						observer.next(resp)
						observer.complete();
					} else {
						observer.next()
						observer.error();
					}
				},
				(error) => {
					observer.error(error);
				})

		})
	}

	/**
	 * Make verify-two-factor api call to IMHOP.
	 * 
	 * @param  data email.
	 * @return  Observable.
	 */
	public verifyTwoFactor = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const cart = this.getStoredCart();
			const request = {
				brand: this.configService.config.powerPanel.brandName,
				email: data.email,
				code: data.code,
				CustomerSessionKey: cart.CustomerSessionKey
			}
			this.headeroptions['withCredentials'] = true;
			const url = this.opApiUrl + '/api/user/verify-two-factor'
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp['success'] === true) {
						observer.next(resp)
						observer.complete();
					} else {
						observer.next()
						observer.error();
					}
				},
				(error) => {
					observer.error(error);
				})

		})
    }

	/**
	 * Make forgot-password api call to IMHOP.
	 * 
	 * @param  data email and username/domain.
	 * @return  Observable.
	 */
	public forgotPassword = function (data) {
        const vm = this;
		return new Observable((observer) => {
			const request = {
				email: data.email,
				username: data.username,
				brand: this.configService.config.powerPanel.brandShortName,
			}
			this.headeroptions['withCredentials'] = true;
			const url = this.opApiUrl + '/api/cart/forgot-password'
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp.success === true) {
						observer.next(resp)
						observer.complete();
					} else {
						observer.error({error: resp.errors[0]});
					}
				})

		})
    }

	public isAuthenticated = function() {
		if(this.ampConnectCookie) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Make forgot-password api call to IMHOP.
	 * 
	 * @param  data email and username/domain.
	 * @return  Observable.
	 */
	public getBilling = function (id) {
        const vm = this;
		return new Observable((observer) => {
			const request = {
				PaymentId: id,
			}
			this.headeroptions['withCredentials'] = true;
			const url = this.opApiUrl + '/api/user/get-billing'
			vm.apiService.http.post(url, request, this.headeroptions)
				.pipe( catchError(error => throwError( error )))
				.subscribe(resp => {
					if(resp.hasOwnProperty('Billing')) {
						observer.next(resp['Billing'])
						observer.complete();
					} else {
						observer.error(resp);
					}
				})

		})
    }

	public getAccountInfo = function () {
		const vm = this;
		return new Observable((observer) => {
			if(this.isAuthenticated()) {
				const request = {}
				this.headeroptions['withCredentials'] = true;
				const url = this.opApiUrl + '/api/user/get-account'
				vm.apiService.http.post(url, request, this.headeroptions)
					.pipe( catchError(error => throwError( error )))
					.subscribe ( resp => {
						if(resp.hasOwnProperty('User')) {
							const countryCode = resp.User.Country.split(':')[0];
							const country = resp.User.Country.split(':')[1];
							const stateCode = resp.User.Province.split(':')[0];
							const state = resp.User.Province.split(':')[1];
							const account = {
								user: {
									first_name: resp.User.FirstName,
									last_name: resp.User.LastName,
									address1: resp.User.Address1,
									address2: resp.User.Address2,
									city: resp.User.City,
									countryCode: countryCode,
									country: country,
									stateCode: stateCode,
									state: state,
									company_name: resp.User.CompanyName,
									email: resp.User.Email,
									phone:  resp.User.Phone,
									postal_code: resp.User.Postal,
									zip: resp.User.Postal
								},
								payments: resp.Payment.MethodsAvailable,
								accountId: resp.User.AccountId,
								currency: resp.Currency
							}
							this.setStoredAccount(account)
							observer.next(account);
							observer.complete();
						} else {	
							this.removeAuthInfo();
							observer.error('not authenticated')

						}
					
				})

			} else {
				this.removeAuthInfo();
				observer.error('not authenticated');
			}
		});
	}

	public removeAuthInfo() {
		const cookieHostname = '.inmotionhosting.com';
		this.cookieService.delete('AmpConnect','/',cookieHostname);
		this.ampConnectCookie = null;
		this.localStorageService.removeItem('op-account')
	}

	public logout() {
		const url = this.opApiUrl + '/api/user/logout';
		return new Observable((observer) => {
			this.headeroptions['withCredentials'] = true;
			this.apiService.http.post(url, this.headeroptions)
				.subscribe ( resp => {
					this.removeAuthInfo();
					observer.next('logged out')
					observer.complete();
				})
			});
	}

	private sameDataFeedParams = function(params) {
		const dataFeedParams = this.getDataFeedParams();
		return _isEqual(dataFeedParams, params)
	}

	public countryLookup(code = null) {
		const vm = this;
		return new Observable((observer) => {
			let url = this.opApiUrl + '/api/country-lookup'
			if(code) {
				url += '/' + code;
			}
			vm.apiService.http.get(url, this.headeroptions)
				.subscribe( 
				(resp) => {
					this.states = resp;
					observer.next(this.states)
					observer.complete()
				},
				(resp) => {
					console.log(resp)
					observer.next('failed')
					observer.error(resp)
				}
			);
		})
		
	}

	public fetchAmpCatalog = function() {
		return new Observable((observer) => {
			const host = this.opApiUrl;
			const url = host + '/api/order/fetch-catalog';
			let updateCartCat = true;
			const cart = this.getStoredCart();
			const cartCat = this.getCartCatalog();
			if(cartCat.hasOwnProperty('data')) {
				if((moment().toISOString() < moment(cart.ttl).toISOString())) {
					updateCartCat = false;
				}
			}
			if(updateCartCat === true) {
				const request = {
					CustomerSessionKey: cart.CustomerSessionKey,
				};
				this.apiService.http.post(url, request, this.headeroptions)
					.subscribe(resp => {
						if(resp.hasOwnProperty('success')) {
							if(resp.success === true) {
								this.setCartCatalog(resp['AvailableProducts']);
								observer.next(this.getCartCatalog());
								observer.complete()
							}
						}
					})
			} else {
				observer.next(cartCat);
				observer.complete()
			}
		})
	}

	public checkAvailability = function(domain, v2 = true) {
		const host = this.opApiUrl;
		let url = host + '/api/registrar-service/check-availability';
		if(v2 === true) {
			url += '-v2';
		}
		url += '?domain=' + domain;
		return this.apiService.http.get(url, this.headeroptions)
	}

	public checkEmail = function(email) {
		const host = this.opApiUrl;
		const cart = this.getStoredCart();
		let csk = '';
		if(cart) {
			csk = cart.CustomerSessionKey;
		}
		const url = host + '/api/user/check-email';
		return this.apiService.http.post(url, {email: email, CustomerSessionKey: csk}, this.headeroptions)
	}

	public addToEvents(event,data) {
		const events = this.getStoredEvents();
		const storedCart = this.getStoredCart();
		let csk = null;
		if(storedCart.hasOwnProperty('CustomerSessionKey')) {
			csk = storedCart.CustomerSessionKey;
		}
		const newEvent =  {
			event: event,
			timeStamp: Date.now(),
		}
		if(event === 'cart:advance') {
			newEvent['from'] = data.from;
			newEvent['to'] = data.to;
			if(data['initial'] === true) {
				newEvent['CustomerSessionKey'] = csk;
			}
		}
		
		events.push(newEvent);
		this.setStoredEvents(events);
	}

}