<mat-card>
	<div class="product-card">
		<div class="product">
			<span class="name">{{this.product.name}}</span>
			<span class="specs">{{this.getSpecs()}}</span>
		</div>
		<div class="quantity">
			<mat-form-field appearance="outline" *ngIf="this.productGroup !== 'cloud_core'">
				<mat-label>Quantity</mat-label>
				<input matInput type="number" [value]="this.product.quantity" (change)="this.triggerInputChange($event)">
			</mat-form-field>
			<span *ngIf="this.productGroup === 'cloud_core'">Quantity: {{this.product.cluster_count ? this.product.cluster_count : '1'}}</span>
		</div>
		<div class="allotment">
			Allotment:
			<span class="amount">{{this.product?.bandwidth?.egress_allotment * this.product?.quantity}} {{this.product?.bandwidth?.allotment_unit}}</span>
		</div>
		<button mat-icon-button color="primary" *ngIf="this.productGroup != 'cloud_core'" (click)="this.updateAddonQuantity(0)"><mat-icon>close</mat-icon></button>
	</div>
</mat-card>
