<mat-card [class.selectable]="this.isSelectable" [class.selected]="this.isSelected" (click)="this.isSelectable && this.handleAddProduct()">
	<div class="product-card">
		<div class="product">
			<span class="name">{{this.product.name}}</span>
			<span class="specs">{{this.getSpecs()}}</span>
		</div>
		<div class="allotment">
			Allotment:
			<span class="amount">{{this.product?.bandwidth?.egress_allotment}} {{this.product?.bandwidth?.allotment_unit}}</span>
		</div>
		<button mat-raised-button color="primary" *ngIf="this.product_group !== 'cloud_core'" (click)="this.handleAddProduct()"><mat-icon>add</mat-icon>Add</button>
	</div>
</mat-card>
