<mat-card>
	<mat-tab-group mat-align-tabs="center"  backgroundColor="primary">
		<ng-container
			*ngFor="let product_group of this.categorized_products | keyvalue"
		>
			<mat-tab
				*ngIf="product_group.value.products?.length > 0"
				[label]="product_group.value.label"
			>
				<div class="details-container">
					<central-product-details
						class="product-detail"
						*ngFor="let product of product_group.value.products"
						[product]="product"
						[product_group]="product_group.key"
						[isSelectable]="product_group.key === 'cloud_core'"
						[isSelected]="
							this.selectedProductsFormGroup.value.cloud_core
								.add_on_code === product.add_on_code
						"
						(addProduct)="this.addProduct($event)"
					></central-product-details>
				</div>
			</mat-tab>
		</ng-container>
	</mat-tab-group>
</mat-card>
