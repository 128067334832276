import { Injectable } from '@angular/core';
import { ConfigurationService } from '../core/configuration.service';
import { io } from 'socket.io-client';
import { AuthService } from '../authentication/services';
import { ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SocketService {
	private socket: any;
	public onConnect = new ReplaySubject();
	public onAuthenticated = new ReplaySubject();
	private authenticated = false;

	constructor(
		public authService: AuthService,
		private configService: ConfigurationService
	) {
		this.connect();
		this.authService.onLogin.subscribe(() => this.authenticate());
		this.authService.onLogout.subscribe(() => this.logout());
	}

	public getSocket() {
		return this.socket;
	}

	public isAuthenticated() {
		return this.authenticated;
	}

	private connect() {
		this.socket = io(
			this.configService.config.hostWebsocket ||
				this.configService.config.host,
			{
				transports: ['websocket', 'polling'],
			}
		);

		this.handleConnectionEvents();
		this.handleAuthEvents();
	}

	private handleConnectionEvents() {
		this.socket.on('connect', () => {
			this.onConnect.next(this.socket);
			this.onConnect.complete();

			if (this.authService.isLoggedIn()) {
				this.authenticate();
			}
		});
	}

	private handleAuthEvents() {
		this.socket.on('authenticated', () => {
			this.onAuthenticated.next();
			this.authenticated = true;
		});
		this.socket.on('authenticate_failure', () => {
			console.log('failed authenticating websocket');
		});
	}

	public authenticate() {
		this.socket.emit('authenticate', {
			token: this.authService.getToken(),
		});
	}

	/**
	 * Clear the token from the current connection.
	 */
	private logout() {
		this.socket.emit('logout');
	}
}
