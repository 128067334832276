<div
	class="ctw-mx-auto ctw-max-w-xl animate__animated animate__zoomInUp ctw-text-center">
	<img class="ctw-mx-auto ctw-mb-10 ctw-w-40"
		src="https://inmotionhosting.github.io/static-assets/illustrations/small/onboard-quickly.svg" />
	<ng-container>
		<h2>Purchase Successful</h2>
		<p>Your receipt will be available in your Account Management Panel</p>
	</ng-container>
	<div class="ctw-text-center ctw-mt-10">
		<button mat-raised-button color="primary" (click)="this.goToAccount()">
			Let's Go! <mat-icon class="next">navigate_next</mat-icon>
		</button>
	</div>
</div>