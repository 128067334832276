import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BandwidthService } from '../../bandwidth.service';

@Component({
	selector: 'central-selected-product-details',
	templateUrl: './selected-product-details.component.html',
	styleUrls: ['./selected-product-details.component.scss'],
})
export class SelectedProductDetailsComponent {
	constructor(
		private bandwidthService: BandwidthService,
	) {}

	@Input() public product;
	@Input() public productGroup;
	@Output() public updateQuantity = new EventEmitter();

	public getSpecs() {
		return this.bandwidthService.getProductSpecs(this.product);
	}

	public updateAddonQuantity(quantity) {
		this.updateQuantity.emit({
			addon: this.product.add_on_code,
			quantity,
		});
	}

	public triggerInputChange(event) {
		const quantity = parseInt(event.target.value, 10);
		if (!isNaN(quantity) && quantity >= 0) {
			this.updateAddonQuantity(quantity);
		}
	}
}
