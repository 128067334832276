// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import brandConfig from '../config/brand';
import configOverride from '../config/override';
import gitConfig from '../../../../build/git-config';
import {  HubspotTrackingService, FacebookService, MatomoAnalyticsService } from '@central/ng-shared';


const config = {
	production: false,
	stackName: 'wordpress_central',
	host: 'http://api.wordpress.test',
	includeTracking: false,
	environment: 'development',
	appPrefix: 'imc',
	gitConfig,
	brandConfig,

	features: {
		referrals: true,
		twoFactor: true,
		notifications: true,
		loginAsUser: true,
		dataCenterSelection: true,
		resize: true,
		connectionUrl: true,
		recommendedTlds: true,
		dynamicTldPricing: true,
		'IMC-683': true,
		bulkTldSearch: true,
		managedHosting: true,
		emailDomainValidation: true,
	},
	beta: {
		serverReset: true,
		blueprints: true,
	},

	// Used to skip auto logout when token expires.
	unauthenticatedRoutePrefixes: [
	],

	litle: {
		// 'paypageId': '7gra9gbLLej6XUJt',
		paypageUrl:
			'https://request.eprotect.vantivprelive.com/eProtect/litle-api2.js',
		paypageId: 'MdnueuzsEPXp6wLN',
		forceFailure: false,
		mockSuccess: false,
		reportGroup: 'InMotion Central',
		url: 'https://request.eprotect.vantivprelive.com/',
	},
	useMocks: false,

	semaphoreUrl: 'http://api.wordpress.test/semaphore',

	powerPanel: {
		ampHost: 'https://testsecure1.inmotionhosting.com',
		opHost: 'https://testorder.inmotionhosting.com',
		chatUrl: '/amp/chat/survey/',
		brandName: "InMotion Hosting, Inc.",
		brandShortName: 'imh',
		phoneNumber: "757.416.6575",
		brandAddress: "555 S Independence Blvd.",
		brandCity: "Virginia Beach, VA 23452",
	},

	screenshotsUrl:
		'https://image.thum.io/get/auth/67911-7ae9ded091e06be48e800740b6561e09/noanimate/fullpage/allowJPG/width/400/',

	screenshotsTTL: 24, // Hours

	recurly: {
		domain: 'stage-boldgrid.recurly.com',
		publicKey: 'ewr1-Y2Qz3N9sxQby2l7iGiX4m6',
	},

	// These are actually prod credentials.
	recaptcha: {
		publicKey: '6Lcp8wsUAAAAAEOiBR0qvr_ymcYb3lcZLBcN0xDi',
		publicKeyInvisible: '6LcWRYEUAAAAAMtZBGHSDpeevihAp_YazsEvU_oQ',
	},

	Fingerprint: {
		ApiKey: 'ANls9UYgRF5carVNTytE',
	},

	routes: {
		login: '/login',
		postLogin: '/projects',
	},

	signInOptions: ['google'],

	trackingConfig: {
		trackers: [
			HubspotTrackingService,
			FacebookService
		]
	},

	trackers: {
		googleAnalytics: false,
		hubspotService: true,
		facebookService: true,
		matomoAnalytics: true,
	},

	optimizely: {
		pageId: '25568740392',
		eventActionPrefix: 'central-',
	},

	matomoAnalytics: {
		eventCategory: 'inmotion_central',
		eventActionPrefix: 'IMC-',
		provider: {
			siteId: 5,
			trackerUrl: 'https://analytics.inmotionhosting.com',
		}
	},

	googleAnalytics: {
		marketing: 'UA-114844436-1',
		eventCategory: 'inmotion_central',
		eventActionPrefix: 'IMC-',
		purchaseStart: "",
		purchaseConversion: "",
		hostingConversion: "",
        routeOverrides: [{
            route: '/amp/',
            marketing: 'UA-114844436-1',
            eventCategory: 'inmotion_central_amp_checkout',
			eventActionPrefix: 'IMC-AMP-',
			purchaseStart: "",
			purchaseConversion: "",
			hostingConversion: "",
        }],
	},

	googleMaps: {
		publicKey: 'AIzaSyC-ajUGKF3S1JHEgWzvQ23RMaU5KNSvdyI',
	},

	rollbar: {
		accessToken: '44e06fd372e549e4bcb6b89cdef7d7a9',
		captureUncaught: true,
		captureUnhandledRejections: true,
		environment: 'development',
		payload: {
			code_version: 'unknown',
		}
	},

	logrocket: {
		appId: 'oooyub/inmotion-central-stage',
        routeOverrides: [{
            route: '/amp/',
            appId: 'oooyub/inmotion-central-stage'
        }]
	},

	zendesk: {
		url: 'https://inmotionhosting1704914566.zendesk.com/',
		chatIsEnabled: true,
		chat: {
			defaultDepartment: '*Live-Technical Support',
			departmentsEnabled: ['*Live-Technical Support'],
		},
		customFields: {},
		inputs: [
			'department',
			'project',
		],
	},
	scripts: [
		{
			id: 'pap_x2s6df8d',
			name: 'postaffiliatepro',
			src: 'https://boldgrid.postaffiliatepro.com/scripts/trackjs.js',
		},
		{
			id: 'ze-snippet',
			name: 'ze-snippet',
			src: 'https://static.zdassets.com/ekr/snippet.js?key=811c71f8-56f5-4d84-b445-6cc9f98b9c9f',
		},
		{
			id: 'recurly',
			name: 'recurly',
			src: 'https://js.recurly.com/v4/recurly.js',
		},
		{
			id: 'googleAnalytics',
			name: 'googleAnalytics',
			src: 'https://www.googletagmanager.com/gtag/js',
		},
		{
			id: 'litle-payments',
			name: 'vantivPayments',
			src: 'https://request.eprotect.vantivprelive.com/eProtect/litle-api2.js',
		},
		{
			id: 'captcha',
			name: 'captcha',
			src: 'https://www.google.com/recaptcha/api.js?render=explicit&onload=captchaOnload',
		},
		{
			id: 'optimizely',
			name: 'optimizely',
			src: 'https://cdn.optimizely.com/js/2680280519.js',
		},
		{
			id: 'hs-script-loader',
			name: 'hubspot',
			src: 'https://js.hs-scripts.com/20482913.js',
		},
		{
			id: 'jquery',
			name: 'jquery',
			src: '/wordpress-central/assets/js/jquery.min.js',
		},
		{
			id: 'cardjs',
			name: 'cardjs',
			src: '/wordpress-central/assets/js/card.js',
		},
		{
			id: 'getResponse',
			name: 'getResponse',
			src: 'https://ga.getresponse.com/script/ga.js?grid=sBDcHWk1ddHwIBw%3D%3D',
		},
	],

	hubspot: {
		eventPrefix: 'mwp',
		forms: {
			trial: {
				portalId: '20482913',
			},
		},
	},

	pageTitles: [
		{
			name: 'Reseller Reports',
			pattern: '^/reports/',
		},
		{
			name: 'My Receipts',
			pattern: '^/account/receipts',
		},
		{
			name: 'Reseller Settings',
			pattern: '^/reseller/settings',
		},
	],

	envato: {
		oauth: {
			clientId: 'boldgrid-ep35abn3',
			redirect_uri:
				'https://www.boldgrid.com/central/oauth/authorization/envato',
			authUrl:
				'https://api.envato.com/authorization?response_type=code&client_id=[CLIENT ID]&redirect_uri=[REDIRECT URI]',
		},
	},

	firebase: {
		apiKey: 'AIzaSyAtrQT0Dq5tHz_vDtGDtvccYaYF6dNlEN4',
		authDomain: 'stage.apps.inmotionhosting.com',
		databaseURL: 'https://boldgrid-8a9f9.firebaseio.com',
		projectId: 'boldgrid-8a9f9',
		storageBucket: 'boldgrid-8a9f9.appspot.com',
		messagingSenderId: '584077191084',
	},

	litleValidation: {
		// Invalid Card Number.
		// (Not Mod10).
		871: 'Invalid card number format. Check and retry.',
		// (Too short).
		872: 'Invalid card number format. Check and retry.',
		// (Too long).
		873: 'Invalid card number format. Check and retry.',
		// (Not a number).
		874: 'Invalid card number format. Check and retry.',
		// (Technical issues).
		875: 'We are experiencing technical difficulties. Please try again later.',
		// (Failure from Server).
		876: 'Invalid card number. Check and retry.',

		// Invalid CVV Number.
		// (Not number).
		881: 'Invalid card validation code. Check and retry. (Not Mod10)',
		// (too short).
		882: 'Invalid card validation code. Check and retry. (Not Mod10)',
		// (too long).
		883: 'Invalid card validation code. Check and retry. (Not Mod10)',
		// (Technical issues).
		889: 'We are experiencing technical with our payment system. Please try again later.',

		'request-timeout':
			'We are experiencing technical difficulties. Please try again later.',
		'script-timeout':
			'We are experiencing technical difficulties. Please try again later.',
		default:
			'We are experiencing technical difficulties. Please try again later.',
	},
	externalUrls: {
		'activating-connect-key':
			'https://www.boldgrid.com/support/getting-to-know-boldgrid/activating-your-boldgrid-connect-key/',
	},
	groupLabels: {
		'group-label-title-case': 'Team',
		'group-label-lower-case': 'team',
		'group-label-title-case-with-article': 'a Team',
		'group-label-lower-case-with-article': 'a team',
		'group-resource-label-lower-case': 'site',
	},
	groupPermissions: {
		owner: {
			label: 'Manager',
			permission: 'manage',
			description: 'Full Administrative Access over the Team.',
		},
		maintainer: {
			label: 'Editor',
			permission: 'write',
			description:
				'Write Access to Projects, Domains and Emails. Cannot manage Team Billing.',
		},
		member: {
			label: 'Member',
			permission: 'read',
			description:
				'Read ONLY Access to Projects, Domains and Emails. Write Access to Playground Environments.',
		},
	},
	teamForm: {
		websiteOptional: true,
	},

	i18n: {
		lang: true,
		currency: false,
	},
};

export const environment = configOverride(config);
