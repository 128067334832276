import { Component, Input } from '@angular/core';
import { BandwidthService } from '../bandwidth.service';

@Component({
	selector: 'central-example-scenarios',
	templateUrl: './example-scenarios.component.html',
	styleUrls: ['./example-scenarios.component.scss'],
})
export class ExampleScenariosComponent {
	constructor(
		private bandwidthService: BandwidthService
	) {}
	@Input() public bandwidthFormGroup;
	public trafficPatterns = [
		{
			percentInPeak: 50,
			peakDurationHours: 8,
		},
		{
			percentInPeak: 60,
			peakDurationHours: 8,
		},
		{
			percentInPeak: 70,
			peakDurationHours: 8,
		},
		{
			percentInPeak: 80,
			peakDurationHours: 8,
		},
	];

	public getEgressMbps(percentInPeak = null, peakDurationHours = null) {
		const bandwidth_TB = this.bandwidthFormGroup.controls.egressUsage.value ?? 0;
		const opts = {
			percentInPeak: percentInPeak ?? this.bandwidthFormGroup.value.percentInPeak,
			peakDurationHours: peakDurationHours ?? this.bandwidthFormGroup.value.peakDurationHours,
		}
		const peak_bandwidth_Mbps = this.bandwidthService.convertTBtoMbpsPerMonth(bandwidth_TB, opts);
		return peak_bandwidth_Mbps.toFixed(2);
	}
}
