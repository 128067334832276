import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';

@Component({
	selector: 'amp-payment-method',
	templateUrl: 'payment-method.component.html',
	styleUrls: ['payment-method.component.scss'],
})
export class PaymentMethodComponent implements AfterViewInit {
	@ViewChild('creditcard') public creditcard: any;
	public paymentMethod = 'Credit Card';
    public paymentDetails = {};
    public existingCards = {};
	public accountInfo: any = {};
    public allowCheck = false;
    public existingCc = {};
    public cardId = 'new';
    public cardModel = {
        method: 'Credit Card',
		card_number: null,
		expiration: null,
		cvv: null
	}

    @Output() public displayNewCard = new EventEmitter();

    public constructor(public ampOpApiService: AmpOpApiService) {}

    public ngAfterViewInit(): void {
        this.getExistingCards();    
    }

    public getInfo() {
        if(this.paymentMethod === 'Credit Card') {
            let cardInfo = {};
            if(this.cardId === 'new') {
                cardInfo = this.creditcard.getInfo();
                cardInfo['method'] = 'Credit Card';
            } else {
                cardInfo = this.paymentDetails;
                cardInfo['method'] = 'Credit Card';
            }
            return cardInfo;
        } else {
            return { method: this.paymentMethod }
        }
        
    }

    public getExistingCards() {
        if(this.accountInfo.hasOwnProperty('payments')) {
            this.existingCards = this.accountInfo['payments'];
        } else {
            this.existingCards = [];
        }
    }

    public selectPaymentMethod() {
        if(!['new','Check'].includes(this.cardId)) {
            this.ampOpApiService.getBilling(this.cardId).subscribe({
                next: (details) => {
                    this.paymentMethod = 'Credit Card';
                    this.paymentDetails = {
                        id: this.cardId,
                        billing: details
                    };
                },
                error: () => {
                    this.paymentDetails = {
                        id: this.cardId,
                        billing: null
                    }
                }
            })
            
        } else if(this.cardId === 'Check') {
            this.paymentMethod = 'Check';
            this.paymentDetails = {}
        } else if(this.cardId === 'new') {
            this.paymentMethod = 'Credit Card';
            this.paymentDetails = {}
        }
    }

    public displayAddNewCardDialog() {
        this.displayNewCard.emit();
    }

    public trimText(text: string): string {
		return text.length > 30 ? text.substr(0, 30) + '...' : text;
	}

    public clearInfo() {
        this.paymentDetails = {}
        this.existingCards = [];
        this.accountInfo = {}
        this.cardId = 'new';
        this.allowCheck = false;
        this.paymentMethod = 'Credit Card';
    }
}