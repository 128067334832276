import { Component, Input } from '@angular/core';

@Component({
	selector: 'central-selected-products',
	templateUrl: './selected-products.component.html',
	styleUrls: ['./selected-products.component.scss'],
})
export class SelectedProductsComponent {
	constructor() {}
	@Input() public selectedProductsFormGroup;

	public updateAddonQuantity(event) {
		const {addon, quantity} = event;
		const addonControl = this.selectedProductsFormGroup.controls.addons;
		const updatedAddons = addonControl.value.map((selectedProduct) => {
			if (selectedProduct.add_on_code === addon) {
				selectedProduct.quantity = quantity;
			}
			return selectedProduct;
		});

		addonControl.setValue(updatedAddons);
	}
}
