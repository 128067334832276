import { Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { CaptchaService } from '../../authentication/login-form/captcha.service';
import { ConfigurationService } from '../configuration.service';

@Component({
	selector: 'central-captcha',
	templateUrl: './captcha.component.html',
	styleUrls: ['./captcha.component.scss'],
})
export class CaptchaComponent {
	@ViewChild('captcha') public captcha: ElementRef;

	@Output() stateChange = new EventEmitter();
	@Input() public state;

	public captchaRendered = false;
	public captchaKey: string;
	public recaptchaResponse: string;

	constructor(
		private captchaService: CaptchaService,
		private configService: ConfigurationService
	) {
		this.captchaKey = this.configService.config.recaptcha.publicKey;
	}

	/**
	 * When the user makes a request that requires a capctha to be submitted,
	 * prompt them.
	 *
	 * @param  finishCb Callback for success.
	 */
	public waitForCaptcha(finishCb: any): void {
		this.state = 'recaptcha';
		this.stateChange.emit(this.state);

		if (
			window['grecaptcha'] &&
			window['grecaptcha'].reset &&
			this.captchaRendered
		) {
			try {
				window['grecaptcha'].reset();
			} catch (e) {
				console.log(e);
			}
		}

		this.captchaService.load().subscribe(() => {
			this.captchaRendered = true;
			window['grecaptcha'].render(this.captcha.nativeElement, {
				theme: 'dark',
				sitekey: this.captchaKey,
				callback: (code) => {
					this.recaptchaResponse = code;
					this.state = 'submitted';
					this.stateChange.emit(this.state);
					finishCb();
				},
			});
		});
	}
}
