<div [hidden]="state !== 'recaptcha'">
	<div class="error-message recaptcha">
		<p>Please complete the following prompt to continue</p>

		<div class="captcha" #captcha></div>

		<div class="action-buttons">
			<button
				mat-raised-button
				mat-button
				(click)="this.state = 'pending'"
			>
				Back
			</button>
		</div>
	</div>
</div>
