import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService, ConfigurationService, DecoderService, ScriptService, TrackingService } from '@central/ng-shared';
import { Subject } from 'rxjs';
import { AfterAppend, SubTrackingService } from '../base/sub-tracking.service';

@Injectable({
    providedIn: 'root',
})
export class HubspotTrackingService extends SubTrackingService implements AfterAppend {
	script = 'hubspot';

	private baseHref = '';
	private config;
	private trackingEvents = {
		fmc_trial_request: '000012145665',
	};

	private accountId;
	private dealId = '';

	constructor(
		private router: Router, 
		private apiService: ApiService, 
		public jwtHelper: DecoderService,
		protected configService: ConfigurationService,
		trackingService: TrackingService,
		scriptService: ScriptService,
	) {
		super(trackingService, scriptService);
		this.baseHref = '/' + window.location.pathname.split('/')[1] || '';
	}

	public afterAppend() {
		this.config = this.configService.config.hubspot || {};
		this.identify();
		this.setupPageView();
	}

	public signup() {
		this.identify();
		this.trackEvent({
			id: this.config?.eventPrefix + '_signup',
		});
	}

	public signin() {
		this.identify();
	}

	public requestTrial() {
		this.trackEvent({
			id: this.trackingEvents.fmc_trial_request,
		});

		this.trackCustomEvent({ name: 'omc_trial_request' });
	}

	public trackEvent(payload) {
		const _hsq = (window['_hsq'] = window['_hsq'] || []);
		_hsq.push(['trackEvent', payload]);
	}

	public onBoarding(payload) {
		this.trackCustomEvent({ name: 'user_onboarding', properties: payload });
	}

	public contactEvaluated() {
		this.trackCustomEvent({ name: 'contact_evaluated' });
	}

	public addBilling() {
		// This event was moved to the backend
	}

	public memberInvite() {
		this.trackCustomEvent({ name: 'add_member' });
	}

	public createResource(payload) {
		this.trackCustomEvent({ name: 'create_resource', properties: payload });
	}

	public selfServiceCheckout(payload) {
		this.trackCustomEvent({
			name: 'self_service_checkout',
			properties: payload,
		});
	}

	public trackCustomEvent(payload) {
		this.identify();
		if (this.config?.customEvents?.[payload.name]) {
			const _hsq = (window['_hsq'] = window['_hsq'] || []);
			_hsq.push([
				'trackCustomBehavioralEvent',
				{
					name: this.config.customEvents[payload.name],
					properties: payload.properties,
				},
			]);
		}
	}

	public setupPageView() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const path = event.urlAfterRedirects.replace(
					/(?=[A-Za-z]*[0-9])(?=[0-9a-z]*[A-Z])(?=[0-9A-Z]*[a-z])[0-9A-Za-z]+/,
					'#'
				);
				const _hsq = (window['_hsq'] = window['_hsq'] || []);
				_hsq.push(['setPath', this.baseHref + path]);
				_hsq.push(['trackPageView']);
			}
		});
	}
	private identify() {
		const _hsq = (window['_hsq'] = window['_hsq'] || []);
		const email = this.jwtHelper.getTokenVal('email');
		if (email) {
			_hsq.push([
				'identify',
				{
					email: email,
				},
			]);
		}
	}

	public getDeal() {
		return this.dealId;
	}

	public setDeal(accountId, dealId) {
		this.accountId = accountId;
		this.dealId = dealId ?? '';
	}

	public abandonCart() {
		if (this.dealId !== '') {
			this.apiService
				.post('/v1/hubspot/' + this.accountId + '/deals', {
					id: this.dealId ?? '',
					status: 'abandoned',
				})
				.subscribe();
		}
	}

	public updateCart(cart) {
		const subject = new Subject<string>();

		if (this.accountId) {
			this.apiService
				.post('/v1/hubspot/' + this.accountId + '/deals', { ...cart, id: this.dealId })
				.subscribe((data: any) => {
					if (this.dealId === '' && data && data.length) {
						this.dealId = data[0]?.data?.dealId.toString() ?? '';
						this.apiService
							.post('/v1/hubspot/' + this.accountId + '/deals', { ...cart, id: this.dealId })
							.subscribe(() => subject.next(this.dealId));
					}
				});
		}

		return subject.asObservable();
	}
}
