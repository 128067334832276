// Modules.
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { SharedModule } from '../shared/shared.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

// Components.
import { InvoiceModule } from '../team/billing/invoice/invoice.module';
import { CartComponent } from './cart/cart.component';
import { CartService } from './cart/cart.service';
import { CatalogDialogComponent } from './catalog-dialog/catalog-dialog.component';
import { CatalogComponent } from './catalog/catalog.component';
import { DiscountComponent } from './discount/discount.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentService } from './payment/payment.service';
import { ProductService } from './product/product.service';
import { UpgradeDialogComponent } from './upgrade-dialog/upgrade-dialog.component';
import { BillingModule, CoreModule } from '@central/ng-shared';
import { AccountService } from './account.service';
import { AuthenticationModule } from '@central/ng-shared';
import { AmpCcComponent } from '../amp/amp-cc/amp-cc.component';
import { PaymentMethodComponent } from '../amp/payment-method/payment-method.component';
import { OpReceiptComponent } from '../amp/op-receipt/op-receipt.component';
import { PasswordCreateComponent } from '../amp/password-create/password-create.component';
import { AmpAccountAuthComponent } from '../amp/account-auth/account-auth.component';
import { AddonsComponent } from './addons/addons.component';
import { BillingAddressComponent } from '../amp/billing-address/billing-address.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		RecaptchaModule,
		InvoiceModule,
		BillingModule,
		AuthenticationModule,
		MatPasswordStrengthModule,
		CoreModule,
	],
	declarations: [
		CatalogDialogComponent,
		PaymentComponent,
		UpgradeDialogComponent,
		CatalogComponent,
		DiscountComponent,
		CartComponent,
		BillingAddressComponent,
		AmpCcComponent,
		PaymentMethodComponent,
		OpReceiptComponent,
		AmpAccountAuthComponent,
		PasswordCreateComponent,
		AddonsComponent
	],
	exports: [
		PaymentComponent,
		CatalogComponent,
		CartComponent,
	],
	providers: [CartService, PaymentService, AccountService, ProductService],
})
export class CheckoutModule {}
