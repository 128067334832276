import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BandwidthService } from '../bandwidth.service';

@Component({
	selector: 'central-product-details',
	templateUrl: './product-details.component.html',
	styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent {
	constructor(
		private bandwidthService: BandwidthService
	) {}

	@Input() public product;
	@Input() public product_group;
	@Input() public isSelectable = false;
	@Input() public isSelected = false;
	@Output() public addProduct = new EventEmitter();

	public handleAddProduct() {
		this.addProduct.emit({product: this.product, product_group: this.product_group});
	}

	public getSpecs() {
		return this.bandwidthService.getProductSpecs(this.product);
	}
}
