import { Component, OnDestroy, OnInit} from '@angular/core';
import { ProjectService } from '../../project.service';
import { ProfileService } from '@central/ng-shared';

@Component({
	selector: 'central-recovery',
	templateUrl: './recovery.component.html',
	styleUrls: ['./recovery.component.scss'],
})
export class RecoveryComponent implements OnInit, OnDestroy {
	state = 'success';

	private subscriptions = [];

	constructor(
		public projectService: ProjectService,
		private profileService: ProfileService,
	) { }

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.subscriptions.push(
					this.projectService.getServerInfo.subscribe((info) => {
						// TODO: Disallow functionality if container is stopped.
						// ...
						this.subscriptions.push(
							this.projectService.getEnvironmentState.subscribe((state) => {
								if (state !== 'pending') {
									this.state = 'success';
								}
							})
						)
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}
}
