import { Component, Inject, Output, EventEmitter } from '@angular/core';
import {
	MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
	MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from '@central/ng-shared';

@Component({
	selector: 'central-delete-dialog',
	templateUrl: 'delete-dialog.component.html',
	styleUrls: ['delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
	@Output() public deleteSuccess = new EventEmitter();
	public status = 'pending';
	public confirmation = '';

	public constructor(
		public matDialogRef: MatDialogRef<DeleteDialogComponent>,
		private apiService: ApiService,
		private snackBar: MatSnackBar,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	/**
	 * Make a deletion call to delete an item.
	 *
	 * @since 1.26.0
	 */
	public doDelete() {
		if (
			this.data.requireNameConfirmation &&
			this.confirmation.toLowerCase().trim() !==
				this.data.nameConfirmation.toLowerCase().trim()
		) {
			return;
		}

		this.status = 'submitted';

		this.apiService.delete(this.data.apiCall, this.data.apiData).subscribe(
			() => {
				this.status = 'success';
				this.matDialogRef.close();
				this.snackBar.open(this.data.label + ' Deleted', '', {
					duration: 5000,
				});
				this.deleteSuccess.emit();
			},
			() => (this.status = 'failed')
		);
	}
}
