import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthenticationModule, CoreModule } from '@central/ng-shared';

import { CheckoutModule } from '../checkout/checkout.module';
import { SharedModule } from '../shared/shared.module';

import { DomainSearchComponent } from './search/domain-search.component';
import { DomainRegisterComponent } from './register/domain-register.component';
import { DomainCollectionComponent } from './management/collection/domain-collection.component';
import { DomainAddSharedComponent } from './management/collection/domain-add/domain-add.component';
import { DomainThirdPartyAddDialogComponent } from './management/collection/domain-third-party-add-dialog/domain-third-party-add-dialog.component';
import { DomainThirdPartyDeleteDialogComponent } from './management/collection/domain-third-party-delete-dialog/domain-third-party-delete-dialog.component';
import { DomainValidator } from './management/collection/domain-add/domain-add.validation';
import { DomainRegistrationComponent } from './management/registration/domain-registration.component';
import { ContactSetComponent } from './management/registration/contact-set/contact-set.component';
import { ContactSetEditComponent } from './management/registration/contact-set/contact-set-edit/contact-set-edit.component';
import { DnsZoneEditorComponent } from './management/dns/dns-zone-editor.component';
import { DomainSelectorComponent } from './management/collection/domain-selector/domain-selector.component';

import { DomainRoutingModule } from './domain.routes';

@NgModule({
	imports: [
		CoreModule,
		SharedModule,
		RouterModule,
		DomainRoutingModule,
		AuthenticationModule,
		CheckoutModule,
	],
	declarations: [
		DomainSearchComponent,
		DomainRegisterComponent,
		DomainCollectionComponent,

		// ⯆⯆⯆⯆⯆ TODO: Refactor ⯆⯆⯆⯆⯆
		DomainAddSharedComponent,
		DomainThirdPartyAddDialogComponent,
		DomainThirdPartyDeleteDialogComponent,
		// ---------------------------------

		// ⯆⯆⯆⯆⯆ TODO: Refactor ⯆⯆⯆⯆⯆
		DomainRegistrationComponent,
		ContactSetComponent,
		ContactSetEditComponent,
		// ---------------------------------

		DnsZoneEditorComponent,
		DomainSelectorComponent,
	],
	exports: [
		DomainSelectorComponent
	],
	providers: [DomainValidator],
})
export class DomainModule {}
