<h2>Playbook History</h2>
<dash-card cardTitle="Playbook History" icon="play_lesson" [disabled]="true" class="ctw-mt-8" [notranslate]="true"
	[transparent]="true">
	<ng-container subTitleHTML *ngIf="this.projectService.environment.fields.playbook_running === 'in-progress'">
		<span class="sub-title ctw-text-base">
			<button mat-stroked-button class="ctw-px-2 mat-raised-button ctw-font-bold ctw-text-sm" color="warn"
				(click)="this.clearPlaybookRunning()">
				Cancel Updates
			</button>
		</span>
	</ng-container>
	<div class="main-content notranslate">
		<div *ngIf="state === 'fail'" class="failed">
			<h3 class="error">Failed to load users.</h3>
			<button mat-raised-button (click)="this.fetch()">Reload</button>
		</div>

		<div class="jobs_table">
			<table mat-table [dataSource]="job_data" matSort>
				<ng-container matColumnDef="UserAdminId">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
					<td mat-cell *matCellDef="let element">
						<a [routerLink]="['/agent/user', element.UserAdminId]">{{element.Email}}</a>
					</td>
				</ng-container>
				<ng-container matColumnDef="Name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Task</th>
					<td mat-cell *matCellDef="let element">
						{{ element.Name }}
					</td>
				</ng-container>
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef mat-sort-header class="ctw-text-center">Status</th>
					<td mat-cell *matCellDef="let element" class="ctw-text-center">
						<mat-spinner *ngIf="element.status === 'processing'" diameter="20"
							class="ctw-m-auto"></mat-spinner>
						<mat-icon *ngIf="element.status === 'pending'"
							class="ctw-text-gray-200">pending_actions</mat-icon>
						<mat-icon *ngIf="element.status === 'success'" class="ctw-text-green-700">done</mat-icon>
						<mat-icon *ngIf="element.status === 'fail'" class="ctw-text-red-700">error_outline</mat-icon>
					</td>
				</ng-container>
				<ng-container matColumnDef="StartedAt">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Last Run</th>
					<td mat-cell *matCellDef="let element">
						{{ element.StartedAt.replace(' ', 'T') + 'Z' | date : 'short' : 'EDT' }}
					</td>
				</ng-container>
				<!--<ng-container matColumnDef="Duration">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
				<td mat-cell *matCellDef="let element">
					{{ getDuration(element) }}
				</td>
			</ng-container>-->
				<!--<ng-container matColumnDef="CompletedAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Completed At</th>
				<td mat-cell *matCellDef="let element">
					{{ element.CompletedAt }} <span *ngIf="element.CompletedAt">UTC</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="UpdatedAt">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Updated At</th>
				<td mat-cell *matCellDef="let element">
					{{ element.UpdatedAt }} UTC
				</td>
			</ng-container>-->
				<ng-container matColumnDef="Action">
					<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
					<td mat-cell *matCellDef="let element">
						<button *ngIf="element.status==='processing'||element.status==='pending'" mat-raised-button
							(click)="this.action(element, 'Stop')" color="warn">Stop</button>
						<button *ngIf="element.status!=='processing'&&element.status!=='pending'" mat-stroked-button
							(click)="this.action(element, 'Restart')">Rerun</button>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
			</table>
		</div>
	</div>
</dash-card>