<div class="example-scenarios">
	<p>Example Scenarios: </p>
	<mat-form-field appearance="fill" class="dropdown">
		<mat-label>Traffic Pattern</mat-label>
		<mat-select [formControl]="bandwidthFormGroup.controls.percentInPeak">
			<mat-select-trigger>
				<span class="trigger">
					<span
						>{{ bandwidthFormGroup.value.percentInPeak }}% of egress over
						{{ bandwidthFormGroup.value.peakDurationHours }} hours</span
					>
					<span>Egress: {{this.getEgressMbps()}} Mbps</span>
				</span>
			</mat-select-trigger>
			<mat-option
				*ngFor="let pattern of trafficPatterns"
				[value]="pattern.percentInPeak"
			>
				<div class="option-text">
					<span>{{ pattern.percentInPeak }}% of egress over
						{{ pattern.peakDurationHours }} hours {{ [60, 70].includes( pattern.percentInPeak ) ? ' (Typical)' : ''}}
					</span>
					<span>{{this.getEgressMbps(pattern.percentInPeak, pattern.peakDurationHours)}} Mbps</span>
				</div>
			</mat-option
			>
		</mat-select>
	</mat-form-field>
</div>
