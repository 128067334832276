<div class="mat-typography wrap">
	<h2>Bandwidth Calculator</h2>
	<p>
		Select a product and enter your monthly bandwidth usage to calculate an
		estimated monthly cost using a traffic pattern where a percentage of your usage
		occurs within a period each day. Your pattern may vary and costs are lower if your
		traffic pattern is spread out further and higher if more concentrated.
	</p>

	<div class="calc-container">
		<div class="inputs ctw-mb-8">
			<div
				class="ctw-flex ctw-flex-row  ctw-items-baseline input-group"
			>
				<div class="bandwidth-input egress">
					<p>Monthly Egress Consumption:</p>
					<mat-form-field appearance="fill">
						<mat-label>Monthly Egress</mat-label>
						<input
							matInput
							type="number"
							placeholder="Monthly Egress"
							class="ctw-h-12"
							[formControl]="
								this.bandwidthFormGroup.controls.egressUsage
							"
							[disabled]="this.state !== 'success'"
						/>
						<span matSuffix>TB</span>
					</mat-form-field>
				</div>

				<div class="bandwidth-input examples">
					<central-example-scenarios
						[bandwidthFormGroup]="this.bandwidthFormGroup"
					></central-example-scenarios>
				</div>
			</div>

			<div class="productSelection">
				<div *ngIf="this.state === 'success'" class="products">
					<h3 class="productsHeader">Products:</h3>
					<central-selected-products
						*ngIf="this.products"
						class="selectedProducts"
						[selectedProductsFormGroup]="this.selectedProductsFormGroup"
					></central-selected-products>
					<central-available-products
						*ngIf="this.products"
						class="availableProducts"
						[products]="this.products"
						[selectedProductsFormGroup]="this.selectedProductsFormGroup"
					></central-available-products>
				</div>
			</div>
		</div>

		<mat-card class="totals ctw-mb-8">
			<div
				class="ctw-flex ctw-flex-col ctw-pb-4 ctw-items-baseline ctw-justify-center"
			>
				<div class="ctw-flex ctw-flex-col ctw-items-center">
					<span class="cost ctw-w-full ctw-flex ctw-justify-between">
						<span class="text-xl ctw-font-bold">
							Estimated Cost:
						</span>
						<span class="text-xl ctw-font-bold">
							{{ this.bandwidthCost | currency }} /mo
						</span>
					</span>
				</div>

				<div class="usage-container" >
					<div class="usage-bars" *ngIf="0">
						<mat-progress-bar
							mode="determinate"
							[value]="this.getUsagePercentage()"
							color="primary"
							[matTooltip]="
								'Bandwidth Allotment: ' +
								this.bandwidthAllotment +
								this.allotmentUnit
							"
							[matTooltipPositionAtOrigin]="true"
							matTooltipClass="mat-typography"
						></mat-progress-bar>
						<mat-progress-bar
							mode="determinate"
							[value]="this.getAllowancePercentage()"
							color="accent"
							[matTooltip]="
								'Bandwidth Usage: ' +
								this.bandwidthFormGroup.controls.egressUsage.value +
								'TB'
							"
							[matTooltipPositionAtOrigin]="true"
							matTooltipClass="mat-typography"
						></mat-progress-bar>
					</div>

					<div class="text-lg ctw-w-full ctw-px-12 ctw-flex ctw-justify-between">
						<span>Allotment: </span>
						<span class="bandwidthAllotment">
							{{this.bandwidthAllotment}} {{this.allotmentUnit}}
							<span class="text-sm ctw-font-normal"> ({{this.bandwidthAllotmentTB  | number : '1.2-3'}} TB)</span>
						</span>
					</div>

					<div class="text-lg ctw-w-full ctw-px-12 ctw-flex ctw-justify-between">
						<span>Usage: </span>
						<span class="bandwidthUsage">
							{{this.convertedMbps  | number : '1.2-3'}} Mbps
							<span class="text-sm ctw-font-normal"> ({{ this.bandwidthFormGroup.controls.egressUsage.value }} TB)</span>
						</span>
					</div>
					<div class="text-lg ctw-w-full ctw-px-12 ctw-flex ctw-justify-between">
						<span>
							Billable Mbps:
						</span>
						<span class="bandwidthUsage">
							{{ this.billable_usage_Mbps | number : '1.2-2' }}
						</span>
					</div>
					<div class="text-lg ctw-w-full ctw-px-14 ctw-flex ctw-flex ctw-justify-between">
						<span>
							Unit cost:
						</span>
						<span class="bandwidthUsage">
							{{this.costPerMbps | currency : 'USD' : 'symbol' : '1.2-3'}}/Mbps
							<span class="text-sm ctw-font-normal">({{this.costPerGB | currency : 'USD' : 'symbol' : '1.2-4'}}/GB)</span>
						</span>
					</div>
				</div>
			</div>
		</mat-card>

	</div>
	<ng-container *ngIf="this.state === 'submitted'">
		<div class="ctw-flex ctw-justify-center">
			<mat-spinner></mat-spinner>
		</div>
	</ng-container>
	<ng-container *ngIf="this.state === 'error'">
		<div class="ctw-flex ctw-justify-center">
			<span class="error">
				We are unable to calculate your estimated cost at this time.
				Please try again later.
			</span>
		</div>
	</ng-container>
</div>
