import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../account/account.service';
import { ApiService } from '../../core/api/api.service';
import { AuthService } from '../../authentication/services/auth.service';
import { ConfigurationService } from '../../core/configuration.service';
import { ProfileService } from '@central/ng-shared';

@Component({
	selector: 'central-organization-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
})
export class OrganizationSettingsComponent implements OnInit {
	@Input() private accountId;

	public state = 'loading';
    public status = 'loading';
	public organizationName = '';
	public orgWebsite = '';
	public contactEmail = '';
	public billingEmail = '';
	public updateMessage = 'Placeholder';
	public websiteOptional = false;

	constructor(
		public apiService: ApiService,
		public authService: AuthService,
		public accountService: AccountService,
		public configurationService: ConfigurationService,
		private profileService: ProfileService
	) {
		this.websiteOptional =
			this.configurationService.config.teamForm?.websiteOptional;
	}

	ngOnInit(): void {
		if (this.configurationService.config.brandConfig.id !== 'imh') {
			this.accountService.fetch(this.accountId).subscribe({
				complete: () => {
					this.state = 'ready';
					this.updateModels();
				},
			});
		} else {
			this.updateModels();
		}
	}

	public updateModels() {
		if (this.configurationService.config.brandConfig.id === 'imh') {
			this.accountService.authAccountUpdate.subscribe((account: any) => {
				if (account.organization_id) {
					this.organizationName = account.name;
					this.contactEmail = account.primary_email;
					this.billingEmail = account.billing_email;
					this.orgWebsite = account.company_website;
					this.status = 'success';
					this.state = 'ready';
				}
			})
		} else {
			this.organizationName = this.accountService.account.name;
			this.contactEmail = this.accountService.account.primary_email;
			this.billingEmail = this.accountService.account.billing_email;
			this.orgWebsite = this.accountService.account.company_website;
		}
	}

	public fixUrls() {
		if (this.orgWebsite && !/^https?:\/\//i.test(this.orgWebsite)) {
			this.orgWebsite = this.orgWebsite.replace(/^.*:\/\//, '');
			this.orgWebsite = 'http://' + this.orgWebsite;
		}
	}

	submit() {
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		const url = this.apiService.formatter.getUrl(
			'/v1/organizations/' + this.accountService.getResourceId()
		);

		this.state = 'submitted';

		this.apiService.http
			.patch(
				url,
				{
					name: this.organizationName,
					primary_email: this.contactEmail,
					billing_email: this.billingEmail || '',
					website: this.orgWebsite,
				},
				{ headers }
			)
			.subscribe({
				next: (account) => {
					this.profileService.fetch();
					this.accountService.updateAccount(account);
					this.updateModels();
					this.state = 'success';
				},
				error: () => {
					this.state = 'failed';
				},
			});
	}
}
