import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigurationService, GoogleAnalyticsService } from '@central/ng-shared';
import { CookieService } from 'ngx-cookie-service';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';
import { AmpLocalStorageService } from '../amp-local-storage-service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AmpDomainComponent } from '../amp-domain/amp-domain.component';

@Component({
	selector: 'amp-checkout',
	templateUrl: 'checkout.component.html',
	styleUrls: ['checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, AfterViewInit {
	@ViewChild('payment', { static: true }) public payment: any;
	@ViewChild('stepper') public stepper: any;
	@ViewChild(AmpDomainComponent, {static: true}) public ampDomain: AmpDomainComponent;
	public status = 'loading';
	public cartStatus = 'pending';
	public iridCookie: string;
	public irmpCookie: string;
	public ampConnectCookie: string;
	public domainsInfo = {};
	public domainName = '';
	public domain = {};
	public initialAdvance = true;
	public accountInfo = {};
	public hideCart = true;
	public freeDomain = false;
	public requiresDomain = false;
	public hideDomainSelect = true;
	public ppHost = '';
	public chatUrl = '';
	public steps = [
		{ step: 1, title: 'HOSTING', isEditable: false, completed: true, disabled:true},
		{ step: 2, title: 'DOMAIN', isEditable: true, completed: false, disabled:false},
		{ step: 3, title: 'COMPLETE', isEditable: false, completed: false, disabled:false},
	]

	public constructor(
		public configService: ConfigurationService,
		public ampOpApiService: AmpOpApiService,
		public localStorage: AmpLocalStorageService,
		private route: ActivatedRoute,
		private router: Router,
		private cookieService: CookieService,
		private cdr :ChangeDetectorRef,
		public googleAnalytics: GoogleAnalyticsService,
		private location: Location,
	) {
		this.ppHost = this.configService.config.powerPanel.ampHost;
		this.chatUrl = this.ppHost + this.configService.config.powerPanel.chatUrl;
		if(this.cookieService.check('irid')) {
			this.iridCookie = this.cookieService.get('irid');
		}
		if(this.cookieService.check('irmp')) {
			this.irmpCookie = this.cookieService.get('irmp');
		}
	}

	public ngOnInit() {
		// Grab the products from the route and format them into a cart object.
		let packages = this.route.snapshot.params['products'];
		let campaign = this.route.snapshot.queryParams['campaign'];
		let affiliates = this.route.snapshot.queryParams['affiliates'];
		if(affiliates) {
			this.ampOpApiService.setAffiliateInfo(affiliates, campaign);
		}
		this.ampOpApiService.clearEventsInfo();
		if(packages) {
			this.ampOpApiService.setPackageInfo(packages);
			this.router.navigateByUrl('/amp/checkout#domain');
		}
		if (['domain','complete','purchase-complete'].includes(this.route.snapshot.fragment)) {
			packages = this.ampOpApiService.getPackageInfo()
			campaign = this.ampOpApiService.getCampaignInfo()
			affiliates = this.ampOpApiService.getAffiliateInfo()
			const params = {
				campaign: campaign,
				affiliates: affiliates
				//cartsession: this.route.snapshot.queryParams['open-session'],
			}
			params['referrer'] = window.document.referrer;

			this.ampOpApiService.checkDataFeed(packages, params)
				.pipe( catchError(error => {
					this.status = 'failed';
					return throwError( error );
				}))
				.subscribe(
				(data) => {
					if(data.hasOwnProperty('packageFound')) {
						if(data['packageFound'] === true) {
							this.payment.useCartService = false
							this.payment.packages = data['packages'];
							this.payment.term = data['term'];
							this.payment.ampTerms = data['terms'];
							this.ampDomain.freeDomain = data['freeDomain'];
							this.freeDomain = data['freeDomain'];
							this.requiresDomain = data['requiresDomain'];
							if(this.requiresDomain) {
								this.hideDomainSelect = false;
							}
							this.payment.ampDomain = {name: this.domainName, freeDomain: data['freeDomain']};
							this.status = 'showcart';
							this.ampOpApiService.createCart(packages, params).subscribe(cart => {
									if(cart) {
										this.ampDomain.cartRefId = cart['RefId'];
										this.ampDomain.cartId = cart['Id'];
										this.cartStatus = 'success';
									}
								})

						} else {
							this.status = 'failed';
						}

					} else {
						this.status = 'failed';
					}
					if(this.status === 'failed') {
						this.ampOpApiService.clearStoredInfo();
					}
				}
			)
		} else {
			this.status = 'failed';
		}
		if(this.status === 'failed' && [null,undefined].includes(packages)) {
			setTimeout( () => {
				window.location.href = 'https://www.inmotionhosting.com';
			}, 3000 );
		}
	}

	public ngAfterViewInit() {
		this.cdr.detectChanges();
		if (['domain', 'complete'].includes(this.route.snapshot.fragment)) {
			if (this.configService.config['googleAnalytics'].routeOverrides) {
				const override = this.configService.config['googleAnalytics'].routeOverrides.find(
					item => this.location.path().startsWith(item.route)
				);
				this.googleAnalytics.trackConversion({
					send_to: override?.purchaseStart ?? ''
				});
			} else {
				this.googleAnalytics.trackConversion({
					send_to: this.configService.config.googleAnalytics.purchaseStart ?? ''
				});
			}
		}
	}

	public setDomainInfo(domainInfo) {
		this.domainsInfo = domainInfo;
	}

	public domainSelected(domain, autoaddon = true) {
		if(domain === 'reset domain') {
			domain = this.domain;
			this.initialAdvance = false;
		} else {
			this.domain = domain;
		}
		const privIndx = this.payment.packages.findIndex(e => e.label === 'Domain Privacy');
		if(privIndx > -1) {
			this.payment.packages.splice(privIndx, 1);
		}
		const domainIndx = this.payment.packages.findIndex(e => e.type === 'domain');
		if(domainIndx > -1) {
			this.payment.packages.splice(domainIndx, 1);
		}
		const hosting = this.payment.packages.find(e => e.type === 'hosting');
		if(hosting.info.FreeDomain === true) {
			this.ampDomain.freeDomain = true;
		}
		if(domain.hasOwnProperty('price')) {

			let domainProduct = this.domainsInfo['data'].find(product => product.PackageToProductOfferingId === domain.id);
			if(domainProduct) {
				domainProduct.PackageToProductOfferingId = domain.id;
				domainProduct.Price = domain.price;
				domainProduct.DiscountPrice = domain.price;
				if(domain.info.freeEligible === true && hosting.info.FreeDomain === true) {
					this.freeDomain = true;
					this.ampDomain.freeDomain = true;
					domainProduct.DiscountPrice = "0.00";
				} else {
					this.freeDomain = false;
					this.ampDomain.freeDomain = false;
				}
				domainProduct = this.ampOpApiService.createCatalogPackage(domainProduct, domain.name);

				domainProduct.planCode = domain.packageId + '_' + domain.id;
				domainProduct.code = domain.packageId + '_' + domain.id;
				domainProduct['options']['price'] = domain.price * 100;
				let privacyAddon = null;
				if(domain.info.privacyEligible === true) {
					domainProduct['addons'].forEach(v => {
						if(v.AddonAutoSelected) {
							const addon = this.ampOpApiService.createCatalogPackage(v, domain.name);
							addon['selected'] = true;
							privacyAddon = addon;
						}
					})
				} else {
					domainProduct.addons = [];
				}
				this.payment.packages.push(domainProduct);
				if(privacyAddon && autoaddon) {
					this.payment.packages.push(privacyAddon)
				}
			}
		}
		this.domainName = domain.name;
		this.payment.ampDomain = {...domain, freeDomain: this.freeDomain};
		const hostingProduct = this.payment.packages.findIndex(product => product.type === 'hosting')
		if(hostingProduct !== -1) {
			this.payment.packages[hostingProduct]['options']['domain_name'] = this.domainName;
		}
		this.payment.cart?.fetchCatalog();
		this.stepper.next();
		this.router.navigateByUrl('/amp/checkout#complete');
		this.ampOpApiService.addToEvents('cart:advance', {from: 'domain', to: 'billing', initial: this.initialAdvance});
		this.hideDomainSelect = true;
		this.hideCart = false;

		// Your earlier ngAfterViewInit() is not triggering
		// Brought code down here to guarantee trigger upone advancement to payment
		// + You were not accounting for the override
		// Applied it for you
		if (this.configService.config['googleAnalytics'].routeOverrides) {
			const override = this.configService.config['googleAnalytics'].routeOverrides.find(
				item => this.location.path().startsWith(item.route)
			);
			this.googleAnalytics.trackConversion({
				send_to: override?.purchaseStart ?? ''
			});
		} else {
			this.googleAnalytics.trackConversion({
				send_to: this.configService.config.googleAnalytics.purchaseStart ?? ''
			});
		}

		// Left this here for you to review Test GTM
		// See also https://github.com/BoldGrid/central-ng-shared/commit/431ad97992d4fe8d02123aa4a4daf698c6e38894
		/*
		if (this.configService.config['googleAnalytics'].routeOverrides) {
			const override = this.configService.config['googleAnalytics'].routeOverrides.find(
				item => this.location.path().startsWith(item.route)
			);

			console.log('googleAnalytics configs overrides for AMP', override);
			console.log('Sending purchase start event', {
				send_to: override.purchaseStart ?? ''
			})
			this.googleAnalytics.trackConversion({
				send_to: override.purchaseStart ?? ''
			});
			const purchasePayload = {
				orderId: 'IMH-TEST-123456',
				total: 100,
				send_to: override?.purchaseConversion ?? '',
			}
			console.log('Sending fake general purchase event', purchasePayload)
			this.googleAnalytics.trackConversion(purchasePayload);
			const hostingPayload = {
				orderId: 'IMH-TEST-123456-H',
				total: 100,
				send_to: override?.hostingConversion ?? '',
			}
			console.log('Sending fake hosting purchase event', hostingPayload)
			this.googleAnalytics.trackConversion(hostingPayload);
		}
		//*/
	}

	public changedStep(step) {
		if(this.steps[step].title === 'DOMAIN') {
			this.router.navigateByUrl('/amp/checkout#domain');
			if(this.domainName === this.ampDomain.tempDomainName) {
				this.ampDomain.domainName = '';
			} else if(this.ampDomain.domainName !== '' && this.ampDomain.domainSelectionType === 'new') {
				this.payment.packages = this.payment.packages.filter(e => e.type !== 'domain')
			}
			this.ampOpApiService.addToEvents('cart:advance', {from: 'billing', to: 'domain', initial: false});

			this.hideCart = true;
			this.hideDomainSelect = false;
		}
	}

	public paymentBack() {
		this.stepper.stepper.previous();
	}

	public paymentSuccess() {
		this.status = 'success';
		this.router.navigateByUrl('/amp/checkout#purchase-success');
	}

	public termUpdated() {
		this.domainSelected('reset domain');
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'openchat':
				this.openChat();
				break;
		}
	}

	public openChat() {
		const screenWidth        = screen.width;
		const screenWidthPercent = 0.25 * screenWidth;
		const minWidth           = 520;
		let width;

		const screenHeight       = 0.78 * screen.availHeight;
		let height;

		const chatParams = new URLSearchParams({
			option: 'sales',
			url: window.location.href
		});

		const chatUrl = this.chatUrl + '?' + chatParams.toString();

		if(screenWidthPercent < minWidth) {
			width = minWidth;
		} else {
			width = screenWidthPercent;
		}

		if(screenHeight > 850) {
			height = 850;
		} else {
			height = screenHeight;
		}

		window.open(chatUrl, 'AMP Chat', 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=' + width + ',height=' + height);
	}
}
