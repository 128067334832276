import { Location } from '@angular/common';
import { Component, HostBinding, ViewChild } from '@angular/core';
import { ApiService } from '../../core/api/api.service';
import { FormModel } from './forgot-password.model';
import { BrandingService } from '../../core/branding/branding.service';
import { CaptchaComponent } from '../../core/captcha/captcha.component';

@Component({
	// eslint-disable-next-line
	selector: 'forgot-password',
	templateUrl: 'forgot-password.component.html',
	styleUrls: ['forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
	@ViewChild(CaptchaComponent) public captchaComponent;
	@HostBinding('class.collpased-logo') public collpasedLogo = true;

	public model = new FormModel('');
	public state = 'pending';

	constructor(
		public apiService: ApiService,
		public brandingService: BrandingService,
		public location: Location
	) {}

	public success() {
		this.state = 'success';
	}

	public fail() {
		this.state = 'fail';
	}

	public onSubmit(isValid: boolean) {
		if (this.state !== 'submitted') {
			if (isValid) {
				this.resetPassword();
			} else {
				this.state = 'fail';
			}
		}
	}

	public requiresInput() {
		return ['pending', 'submitted', 'fail'].includes(this.state);
	}

	public resetPassword() {
		this.state = 'submitted';

		this.apiService
			.post('resetPassword', {
				email: this.model.email,
				'g-recaptcha-response': this.captchaComponent.recaptchaResponse,
			})
			.subscribe(
				(response) => {
					const result = response['result'];

					if (response['message'] === 'Invalid Captcha.') {
						this.captchaComponent.waitForCaptcha(() =>
							this.resetPassword()
						);
					} else if (result && result.data && result.data.success) {
						this.success();
					} else {
						this.fail();
					}
				},
				(err) => {
					this.fail();
				}
			);
	}
}
