import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Observable, concat } from 'rxjs';
import currency from 'currency.js';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../core';
import locationData from './location-data';
import { AuthService } from '@central/ng-shared';
import { Router } from '@angular/router';

enum InventoryStatus {
	available = 'available', // In stock or prewarm available
	outOfStock = 'out-of-stock', // Available at the datacenter but not in stock
	unavailable = 'unavailable', // Not available at the datacenter
}

@Component({
	selector: 'central-hardware-selector',
	templateUrl: './hardware-selector.component.html',
	styleUrls: ['./hardware-selector.component.scss'],
})

export class HardwareSelectorComponent implements OnInit, OnChanges {
	@Output() public sizeChanged = new EventEmitter();
	@Output() public availabilityChanged = new EventEmitter();
	@Output() public toggleHardwareSelector = new EventEmitter();
	@Input() public filtersEnabled = true;
	@Input() public selectedProduct : any = ['storage_and_compute_standard'];
	@Input() public selectedLocation = 'pod1';
	@Input() public display = 'catalog-view';
	@Input() public trial_data = {
		has_trial: false,
		available_trial_products: [],
		trial_length: null,
	};
	@Input() public term = 'monthly';
	@Input() public showHardwareSelector = true;

	// quantity / radio
	@Input() public selectionType = 'quantity';

	@Input() public autoDeployableOptions = false;

	public state = 'loading';
	public clusterSize = 3;
	public selected;
	public addons;
	public prices;
	public catalogProducts = [];

	public validPCCServers = [];

	public validBareMetalServers = [];

	public products : any[] = [];

	public stockSortedProducts = {
		available: [],
		outOfStock: [],
		unavailable: [],
		getAll: () => this.stockSortedProducts.available.concat(this.stockSortedProducts.outOfStock, this.stockSortedProducts.unavailable),
	};
	public quantityOptions = [];

	public displayedColumns: string[] = [
		'radio', 'size', 'cpu', 'cores', 'speed', 'disk', 'memory', 'network', 'price'];

	public agreementPriceDiscounts = {
		"monthly": 0,
		"oneYear": 0.075,
		"twoYear": 0.14,
		"threeYear": 0.20,
		"fourYear": 0.28,
		"fiveYear": 0.34,
	};

	private availableProducts = {};
	private typesWithAvailablePrewarms = {
		product_types: [],
		hardware_types: [],
	};

	private orderConfig;

	private pccSelectionState;
	private baremetalSelectionState;

	public constructor(
		private http: HttpClient,
		private configService: ConfigurationService,
		private router: Router,
		@Optional() private auth: AuthService,
	) {}

	public ngOnInit(): void {
		this.fetchProductData().subscribe( {
			error: () => {
				this.state = 'error';
			},
			complete: () => {
				this.formatProducts();
				this.quantityOptions = Array.from(Array(10), (_, i) => i);
				this.initQuantity();
				this.initProductSelection();
				this.state = 'success';
			}
		});
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ((changes.selectionType || changes.selectedLocation || changes.term) && (this.state === 'success' || this.state === 'error')) {
			this.catalogProducts = this.selectionType === 'quantity' ? this.validBareMetalServers : this.validPCCServers;
			if( changes.selectedLocation ) {
				this.state = 'loading';
				this.fetchProductData().subscribe({
					complete: () => {
						this.formatProducts();
						this.resetSelectedProducts();
						this.state = 'success';
					},
					error: (error) => {
						this.state = 'error';
					}
				});
			} else {
				this.formatProducts();
				this.resetSelectedProducts();
			}
		}
	}

	public resetSelectedProducts() {
		const validPccSelectionState = this.pccSelectionState?.some( ( _product ) => this.productIsAvailable(_product.datacenters) );
		const validBMSelectionState = this.baremetalSelectionState?.some( ( _product ) => this.productIsAvailable(_product.datacenters) );

		this.products.forEach( ( product ) => {
			product.selected = false;
			product.quantity = 0;

			if ( this.selectionType === 'radio' ) {
				const previouslySelectedProduct = this.pccSelectionState?.find( ( _product ) => _product.code === product.code );

				if( validPccSelectionState ) {
					product.selected = previouslySelectedProduct?.selected ? previouslySelectedProduct.selected : false;
				} else if (product.code === this.default_product) {
					this.toggleHardwareSelector.emit(true);
					product.selected = true;
				}

				if (product.selected) {
					this.selected = product;
				}
			} else {
				const previouslySelectedBaremetalProduct = this.baremetalSelectionState?.find( ( _product ) => _product.code === product.code);

				if( validBMSelectionState ) {
					product.quantity = previouslySelectedBaremetalProduct?.quantity ? previouslySelectedBaremetalProduct.quantity : 0;
				} else if ( product.code === this.default_product) {
					this.toggleHardwareSelector.emit(true);
					product.quantity = 1;
				}
			}
		} );

		// wait for the view to update
		setTimeout( () => {
			if ( 'radio' === this.selectionType ) {
				this.changeSelected(this.selected);
			} else {
				this.changeQuantity();
			}
		}, 0 );
	}

	private formatProducts() {
		const agentOnlyProducts = this.orderConfig['agentOnlyProducts'] ?? [];
		// filter out products that are not available in that were not in addons
		this.catalogProducts = this.catalogProducts.filter( ( addonCode ) => {
			const allowedAddon = this.isAdmin() ? true : !agentOnlyProducts.includes(addonCode);
			return allowedAddon && !! this.addons.find( ( _addon ) => _addon.add_on_code === addonCode );
		});

		this.products = this.catalogProducts.map( ( addonCode ) => {
			const addon = this.addons.find( ( _addon ) => _addon.add_on_code === addonCode );
			const hardwareTypes = addon.hardwareTypes[0];

			const drivesText = [];
			Object.entries(hardwareTypes.storage).forEach( ( [type, drive] ) => {
				( hardwareTypes.storage[type].drives || [] ).forEach( ( _drive ) => {
					const raw = _drive.raw >= 1000 ? _drive.raw / 1000 : _drive.raw;
					const unit = _drive.raw >= 1000 ? 'TB' : 'GB';
					const count = _drive.count > 1 ? _drive.count + 'x ' : '';
					let label = type === 'hdd' ? 'HDD' : 'NVMe';
					label = type === 'boot' ? 'Boot Disk' : label;
					drivesText.push( count + raw + unit + ' ' + label );
				} );
			} );

			let totalStorage = 0;
			Object.entries(hardwareTypes.storage).forEach( ( [key, drive] ) => {
				let nodeStorage = 0;
				( hardwareTypes.storage[key].drives || [] ).forEach( ( _drive ) => {
					nodeStorage += _drive.raw * _drive.count;
				} );

				totalStorage += nodeStorage;
			} );

			const size = addon.name.replace(/(Cloud Core|Storage and Compute|Compute)( -)? ?/g, '');

			const product = {
				hidden: false,
				code: addonCode,
				cpuCount: hardwareTypes.processor.cpuCount,
				size: size,
				cpu: hardwareTypes.processor.name,
				cpuBrand: hardwareTypes.processor.name.toLowerCase().includes( 'amd' ) ? 'amd' : 'intel',
				model: hardwareTypes.model,
				cores: hardwareTypes.processor.raw_cores + 'C/' + hardwareTypes.processor.raw_threads + 'T',
				rawCores: hardwareTypes.processor.raw_cores,
				rawThreads: hardwareTypes.processor.raw_threads,
				speed: hardwareTypes.processor.speed,
				memory: hardwareTypes.ram.raw + 'GB' + ' ' + hardwareTypes.ram.type + ' ' + hardwareTypes.ram.speed,
				rawMemory: hardwareTypes.ram.raw,
				disk: drivesText,
				totalStorage,
				// format price to 2 decimals
				price: '$' + currency(addon.hourly_cost_ltu) + '/hour',
				agreementPricing: this.getAgreementPricing(addon),
				egress: `${addon.bandwidth.egress_allotment}${addon.bandwidth.allotment_unit}`,
				network: `${hardwareTypes.network.count}X${hardwareTypes.network.speed}Gbit`,
				datacenters : hardwareTypes.datacenters,
				locations: this.getLocationsData( hardwareTypes.datacenters ),
				disabled: !this.productIsAvailable(hardwareTypes.datacenters),
				available: this.availableProducts[addonCode] ?? 0,
				cluster_count: addon.cluster_count ?? 1,
				gpu: hardwareTypes.gpu,
				hardwareTypes
			};

			product['status'] = this.getInventoryStatusLabel(product);
			return product;
		} );

		this.stockSortedProducts.available = this.filterProductsByInventoryStatus(InventoryStatus.available);
		this.stockSortedProducts.outOfStock = this.filterProductsByInventoryStatus(InventoryStatus.outOfStock);
		this.stockSortedProducts.unavailable = this.filterProductsByInventoryStatus(InventoryStatus.unavailable);
	}

	private getAgreementPricing( addon ) {
		let monthlyPrice = addon.hourly_cost_ltu * 24 * 30;

		// radio select is pcc only
		if ( this.selectionType === 'radio' ) {
			monthlyPrice = monthlyPrice * this.clusterSize;
		}

		const agreementPricing = {};
		for ( const [agreement, discount] of Object.entries(this.agreementPriceDiscounts) ) {
			agreementPricing[agreement] = currency(monthlyPrice * (1 - discount)).format();
		}

		return agreementPricing;
	}

	private getLocationsData( datacenters ) {
		return datacenters.map( ( datacenter ) => locationData.find( ( location ) => location.pod === datacenter ) ).filter( ( location ) => location != null);
	}

	private getAddonCodesFromConfig(type) {
		return Object.values(this.orderConfig['products'] ?? {})
			.filter((product_config) => product_config['type'] === type)
			.map((product_config) => product_config['addon']);
	}

	private fetchOrderConfigData() {
		const url = this.configService.config.host + '/v1/orders/pricing/config';
		return this.http.get( url ).pipe(
			tap((config) => {
				this.orderConfig = config;
				this.validBareMetalServers = this.getAddonCodesFromConfig('baremetal');
				this.validPCCServers = this.getAddonCodesFromConfig('pcc');
				this.catalogProducts = this.selectionType === 'quantity' ? this.validBareMetalServers : this.validPCCServers;
			}),
		);
	}

	private fetchProductData() {
		const url = this.configService.config.host + '/v1/products/addons';
		return concat( ...[
			this.fetchOrderConfigData(),
			this.http.get( url ).pipe( tap( ( response ) => {
				this.addons = response;
			} ) ),
			this.getAvailablePrewarms(),
			this.getAvailableProducts(),
		] );
	}

	public initQuantity() {
		if ( this.selectionType === 'quantity' && this.selectedProduct.length > 0 ) {

			const selectedProducts = this.selectedProduct.map( ( product ) => {
				const selected = this.products.find( ( _product ) => _product.code === product );
				if ( selected ) {
					selected.quantity = selected.quantity || 0;
					selected.quantity++;
					return selected;
				} else {
					console.log( 'product not found:' + product );
				}
			} );
			this.baremetalSelectionState = selectedProducts;
			this.sizeChanged.emit(selectedProducts);
		}
		this.products.map( ( product ) => {
			product.quantity = product.quantity || 0;
		} );
	}

	public initProductSelection() {
		if ( this.selectedProduct.length > 1 || this.selectionType === 'quantity' ) {
			return;
		}
		const product = this.products.find( ( _product ) => _product.code === this.selectedProduct[0] );

		if ( product ) {
			this.unselect();

			product.quantity = 1;
			product.selected = true;
			this.selected = product;
			this.pccSelectionState = [product];
			this.sizeChanged.emit([product]);
		}
	}

	public changeSelected(selected) {
		this.unselect();
		selected.selected = true;

		this.pccSelectionState = [selected];
		this.sizeChanged.emit( [ selected ] );
	}

	public changeQuantity() {
		const selected = [];

		for ( const product of this.products ) {
			if ( product.quantity > 0 ) {
				for ( let i = 0; i < product.quantity; i++ ) {
					selected.push( product );
				}
			}
		}

		this.baremetalSelectionState = selected;
		this.sizeChanged.emit(selected);
	}

	public rowClick(row) {
		if ( 'radio' === this.selectionType ) {
			this.selected = row;
			this.changeSelected(this.selected);
		}

	}

	public navigateToConfigure( product ) {
		let url = this.configService.config.centralUrl + '/checkout?';
		url += 'type=baremetal&term=threeYear&product=' + product.code;

		window.location?.assign( url );
	}

	public contactSales() {
		if ( this.auth.isLoggedIn() ) {
			this.router.navigate( [
				'/account',
				this.auth.getAccountId(),
				'help'
			], { queryParams: { action: 'new-ticket' } });
		} else {
			window.open('https://openmetal.io/schedule-meeting/', '_blank');
		}
	}

	private unselect() {
		this.products.map( ( product ) => {
			product.selected = false;
			product.quantity = 0;
		} );
	}

	private getAvailablePrewarms() {
		const formattedPod = this.selectedLocation.replace(/(\D+)(\d+)/, "$1_$2");
		const url = this.configService.config.host + '/v1/clouds/ready/availability';

		return new Observable( (observer) => {
			this.http
			.get(url, {
				params: {
					pod_location: formattedPod,
				},
			}).subscribe({
				next: (response) => {
					this.typesWithAvailablePrewarms = {
						product_types: response['available_types'] ?? [],
						hardware_types: (response['available_types'] ?? []).map(
							(type) =>
								this.addons?.find(
									(_addon) => _addon.add_on_code === type,
								)?.hardwareTypes[0].hardware_id,
						),
					};
					observer.next(response);
					observer.complete();
				},
				error: () => {
					this.typesWithAvailablePrewarms = {
						product_types: [],
						hardware_types: [],
					};
					observer.next([]);
					observer.complete();
				},
			});
		});
	}

	private getAvailableProducts(): Observable<any> {
		const formattedPod = this.selectedLocation.replace(/(\D+)(\d+)/, "$1_$2");
		const url = this.configService.config.host + '/v1/clouds/inventory/availability';

		return this.http
			.get(url, {
				params: {
					pod_location: formattedPod,
				}
			} ).pipe( tap( ( response ) => {
				this.availableProducts = response;
				this.availabilityChanged.emit({
					inventory: this.availableProducts,
					prewarms: this.typesWithAvailablePrewarms,
				});
			} ) ).pipe (catchError( ( error ) => {
				this.availableProducts = {};
				throw error;
			} ) );
	}

	private productIsAvailable(datacenters) {
		const validLocation = datacenters?.includes(this.selectedLocation) ?? false;
		return validLocation;
	}

	private productIsInStock(product) {
		const minimumQuantity = product.cluster_count ?? 1;
		const selectedQuantity = (product.quantity ?? 0) * minimumQuantity;
		return (product.available >= minimumQuantity && product.available >= selectedQuantity) || this.hasAvailablePrewarm(product) || this.hasAvailablePrewarmForHwType(product);
	}

	private hasAvailablePrewarm(product) {
		return this.typesWithAvailablePrewarms.product_types.includes(product.code);
	}

	private hasAvailablePrewarmForHwType(product) {
		const hwType = product.hardwareTypes?.hardware_id;

		// Check if there is a prewarm with a hw type that matches the selected product hw type in order to support prewarm checks for non PCC products.
		return this.typesWithAvailablePrewarms.hardware_types.includes(hwType);
	}

	public	trialAvailable(product: any) {
		const validSize = this.trial_data?.available_trial_products?.includes(product.code) ?? false;
		const inStock = this.isAdmin() ? this.productIsInStock(product) : this.hasAvailablePrewarm(product);
		return  (
			this.trial_data.has_trial && validSize && this.trial_length && inStock && this.autoDeployableOptions && this.productIsAvailable(product.datacenters)
		);
	}

	private getInventoryStatusLabel(product) {
		const isAutoDeployable =  this.hasAvailablePrewarm(product) && this.productIsAvailable(product.datacenters) && this.autoDeployableOptions;
		if ( this.isAdmin() ? this.productIsInStock(product) : isAutoDeployable ) {
			return InventoryStatus.available;
		} else if (this.productIsAvailable(product.datacenters)) {
			return InventoryStatus.outOfStock;
		} else if (!this.productIsAvailable(product.datacenters)) {
			return InventoryStatus.unavailable;
		}
	}

	private filterProductsByInventoryStatus(status: InventoryStatus) {
		if ( status === InventoryStatus.available) {
			return this.products.filter((product) => product.status === InventoryStatus.available);
		} else if(status === InventoryStatus.outOfStock) {
			return this.products.filter((product) => product.status === InventoryStatus.outOfStock);
		} else if (status === InventoryStatus.unavailable) {
			return this.products.filter((product) => product.status === InventoryStatus.unavailable);
		} else {
			return [];
		}
	}

	private isAdmin() {
		return this.auth?.isAdmin() || false;
	}

	private get default_product() {
		const location = this.selectedLocation;
		if( location === 'pod2' ) {
			return this.selectionType === 'radio' ? 'starter_large_v2_1' : 'storage_and_compute_v2_1';
		} else if( location === 'pod3' ) {
			return this.selectionType === 'radio' ? 'starter_large_v2' : 'storage_and_compute_large_v2';
		} else {
			return this.selectionType === 'radio' ? 'starter_standard' : 'storage_and_compute_standard';
		}
	}

	public get trial_length() {
		return this.trial_data.trial_length ?? null;
	}

	public get selectedProducts() {
		return this.products.filter( ( product ) => product.selected || product.quantity > 0);
	}

	public showStockStatusHeader(product) {
		return product.code === this.stockSortedProducts.available[0]?.code || product.code === this.stockSortedProducts.outOfStock[0]?.code || product.code === this.stockSortedProducts.unavailable[0]?.code;
	}
}