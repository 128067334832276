<div class="login valign submission-form" *ngIf="requiresInput()">
	<img class="logo" [src]="this.brandingService.getBrandOpt('logo')" />
	<h1>Forgot Password?</h1>
	<p>
		Enter your email address &amp; we'll send you a link to reset your
		password.
	</p>
	<p class="error">
		<span *ngIf="this.state === 'fail'">The email you entered was invalid. Please try again.</span>
	</p>
	<form (ngSubmit)="onSubmit(emailField.valid)" #forgotPassword="ngForm">
		<mat-form-field>
			<input matInput class="validated forgot-email" name="emailField" [(ngModel)]="model.email"
				placeholder="e.g. youremail@gmail.com" dividerColor="accent" type="email" ngDefaultControl required
				#emailField="ngModel" />
			<mat-error class="error" *ngIf="emailField.errors?.required">
				You must provide a valid email.
			</mat-error>
		</mat-form-field>
		<div class="action-buttons right-align">
			<button type="button" mat-ripple mat-raised-button (click)="this.location.back()">
				Back
			</button>
			<button type="submit" [disabled]="state === 'submitted'" class="btn-spinner" mat-ripple mat-raised-button
				mat-raised-button color="accent">
				<mat-spinner diameter="20" *ngIf="state === 'submitted'"></mat-spinner>Next
			</button>
		</div>
	</form>
</div>
<central-captcha [(state)]="this.state"></central-captcha>
<div class="login valign email-sent" *ngIf="state === 'success'">
	<h1>Check Your Email</h1>
	<p>
		If an account with the email
		<strong>{{ this.model.email }}</strong> exists, we sent you a link to
		reset your password. Head over to your mailbox to get your reset link
		and create your brand new password.
	</p>
	<div class="action-buttons">
		<button mat-raised-button mat-raised-button color="accent" [routerLink]="['/login']">
			Done
		</button>
	</div>
</div>
