import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';
import { AmpLocalStorageService } from '../amp-local-storage-service';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
	selector: 'amp-domain',
	templateUrl: 'amp-domain.component.html',
	styleUrls: ['amp-domain.component.scss'],
})
export class AmpDomainComponent implements OnInit, AfterViewInit {
	public status = 'loading';
	public domainValidator;
	public domainName = '';
	public tempDomainName = '';
	public cartRefId = '';
	public cartId = '';
	public domainAvailability = '';
	public allowedDomains = [];
	public searchedDomain = [];
	public searchedDomainUnavailable = false;
	public domainSelectionType = "new";
	public domainValid;
	public domainError = ''
	public domainPackageId;
	public domainProductId;
	public domainSearchLoading = false;
	public allowedTlds = [];
	public domainTldList = [];
	public freeDomain = false;
	@Output() public domainSelected = new EventEmitter();
	@Output() public domainInfo = new EventEmitter();

	public constructor(
		public ampOpApiService: AmpOpApiService,
		public localStorage: AmpLocalStorageService,
		private cdr :ChangeDetectorRef
	) {}

	public ngOnInit() {
		this.domainValidator = new UntypedFormControl('', [
			Validators.pattern('^((?!-)[-0-9a-z]{0,62}[0-9a-z](.(?!$)|$)){2,3}'),
		]);
		this.getAllowedTlds()
	}

	public ngAfterViewInit(): void {
		this.cdr.detectChanges();	
	}

	public getAllowedTlds() {
		this.domainSearchLoading = true;
		this.ampOpApiService.checkDomainInfo().subscribe( d => {
			const regex = /\(\.([^)]+)\)/;
			const tlds = [];
			d['data'].forEach(tld => {
				const matches = tld.ProductName.match(regex);
				if(matches) {
					tlds.push(matches[1])
				}
			});
			this.domainTldList = tlds;
			this.domainValidator = new UntypedFormControl('', [
				Validators.pattern('^(?!-)([0-9a-zA-Z-]{0,62})?[0-9a-zA-Z]\.(' + this.domainTldList.join('|') + '|)$'),
			]);
			this.domainSearchLoading = false;
			this.domainInfo.emit(d);
		})
	}

	public handleDomainType() {
		this.domainName = '';
		this.allowedDomains = [];
		this.searchedDomain = [];
		this.domainValid = undefined;
		if(this.domainSelectionType === 'new') {
			this.domainValidator = new UntypedFormControl('', [
				Validators.pattern('^(?!-)([0-9a-zA-Z-]{0,62})?[0-9a-zA-Z]\.(' + this.domainTldList.join('|') + '|)$'),
			]);
		}
		if(this.domainSelectionType === 'existing') {
			this.domainValidator = new UntypedFormControl('', [
				Validators.pattern('^((?!-)[-0-9a-z]{0,62}[0-9a-z](.(?!$)|$)){2,3}'),
			]);
		}
	}

	public select (i, searchedDomain = false) {
		const selected = searchedDomain ? this.searchedDomain[i] : this.allowedDomains[i];
		const domainInfo = {
			name: selected.domainName,
			price: selected.price,
			id: selected.id,
			productId: this.domainProductId,
			packageId: this.domainPackageId,
			info: selected
		}
		this.domainSelected.emit(domainInfo);
	}

	public clearDomainSearch() {
		this.searchedDomainUnavailable = false;
		this.allowedDomains = [];
		this.searchedDomain = [];
		this.domainName = '';
	}

	updateDomainAvailability(autoSendToCart = true) {
		this.isDomainValid();
		if(this.domainValid) {
			this.domainName = this.domainName.toLowerCase();
			this.domainSearchLoading = true
			this.ampOpApiService.fetchAmpCatalog().subscribe(catalog => {
				if(catalog.hasOwnProperty('data')) {
					this.allowedTlds = this.domainTldList.map( e => catalog['data'].find((p) => p.Name === 'Domain Registration (.' + e + ')'))
					this.ampOpApiService.checkAvailability(this.domainName)
						.subscribe( 
							(resp) => {
								if(resp && resp.hasOwnProperty('TldProducts')) {
									const availableDomains = resp.available;
									const tlds = Object.keys(resp.TldProducts);
									const tldProducts = resp.TldProducts;
									this.domainPackageId = resp.PackageId;
									this.domainProductId = resp.ProductId;
									this.searchedDomainUnavailable = false;
									this.allowedDomains = [];
									this.searchedDomain = [];
									let includesTld = false;
									tlds.forEach((tld) => {
										const info = tldProducts[tld];
										const fullDomain = this.domainName.split('.')[0] + '.'+ tld;
										const searchedDomain = this.domainName === fullDomain;
										const opInfo = this.allowedTlds.find(p => p.Name === 'Domain Registration (.' + tld + ')');
										if(this.domainName.split('.').length > 0) {
											if(this.domainName.split('.')[1] === tld) {
												includesTld = true;
											}
										}
										if(searchedDomain === true && includesTld === true) {
											this.searchedDomainUnavailable = !availableDomains.includes(fullDomain)
										}
										const tldDomain = {
											tld: tld,
											domainName: fullDomain,
											searchedDomain: searchedDomain,
											availability: availableDomains.includes(fullDomain) ? 'available' : 'taken',
											selected: false,
											id: info.Id,
											price: info.Price,
											info: {...info, ...opInfo},
											freeEligible: (opInfo.LegacyProperties.DOMAIN_CREDIT_ELIGIBLE === "1") ? true : false,
											privacyEligible: opInfo.LegacyProperties.DOMAIN_PRIVACY_ELIGIBLE === "1" ? true : false
										}
										this.allowedDomains.push(tldDomain)
									})
									this.searchedDomain = this.allowedDomains.filter(t => t.searchedDomain)
									if(this.searchedDomainUnavailable === false && this.searchedDomain.length > 0 && autoSendToCart === true) {
										this.select(0,true);
									}
								}
								this.domainSearchLoading = false
							},
							(err) => {
								this.domainSearchLoading = false;
								this.domainError = 'Something went wrong. Please try again.'
								if(err.hasOwnProperty('error')) {
									if(err.error.hasOwnProperty('detail')) {
										this.domainError = err.error.detail;
									}
								}
								this.domainValidator.status = 'INVALID';
								this.domainValid = false;
							}
						)
				}
				
			});
		}
	}

	public setDomainSelection(tempdomain = false) {
		if(tempdomain) {
			const subdomain = this.cartRefId.toString() + this.cartId.toString();
			this.tempDomainName = subdomain + '.temporary.link';
			if(subdomain.length < 10) {
				const newSub = this.createRandomString(12)
				this.tempDomainName = newSub + '.temporary.link';
			}
			this.domainName = this.tempDomainName;
			this.domainSelected.emit({name: this.domainName})
		} else {
			if(this.domainValid) {
				if(this.domainName !== '') {
					this.domainSelected.emit({name: this.domainName})
				} else {
					this.domainValid = false;
				}
			}
		}
	}

	public getPriceClass(tld) {
		const classes = [];
		if(tld.freeEligible && this.freeDomain) {
			classes.push('ctw-line-through');
		}
		if(tld.availability === 'taken') {
			classes.push('ctw-text-red-700');
		}
		return classes.join(' ');
	}

	public domainChange(key) {
		if(key.key === 'Enter') {
			if(this.domainSelectionType === 'new') {
				this.updateDomainAvailability();
			} else {
				this.setDomainSelection();
			}
		} else {
			this.allowedDomains = [];
			this.searchedDomain = [];
			this.isDomainValid();
		}
	}

	isDomainValid() {
		this.domainValid=true;
		this.domainError = ''
		const tld = '.' + this.domainName.split('.').slice(1).join('.');
		if (this.domainName.trim() === '') {
			this.domainError = 'Enter a domain name';
		}
		if (this.domainName.match(/\.$/) || !this.domainName.match(/^[A-Za-z0-9\.-]*$/)) {
			this.domainValidator.status = 'INVALID';
			this.domainError = 'Not a valid domain name';
		}
		if(this.domainValidator){
			if(this.domainValidator.hasError('pattern')) {
				if(!this.domainTldList.includes(tld.replace(/\./g, '')) && this.domainSelectionType === 'new') {
					this.domainError = 'Unable to register <strong>' + tld.replace(/\.\./g, '') + '</strong> domains at this time';
				} else {
					this.domainError = 'Not a valid domain name';
				}
			}
		}

		if(this.domainError.length > 0) {
			this.domainValid = false;
		}

	}

	public createRandomString(length) {
		const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
		let result = "";
		for (let i = 0; i < length; i++) {
		  result += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return result;
	  }
}
