import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { FacebookService } from './facebook/facebook.service';
import { GoogleAnalyticsService } from './google/google.service';
import { OptimizelyService } from './optimizely/optimizely.service';
import { TwitterAnalyticsService } from './twitter/twitter.service';
import { BingService } from './bing/bing.service';
import { HubspotTrackingService } from './hubspot/hubspot.service';
import { LinkedinService } from './linkedin/linkedin.service';
import { CoreModule } from '../core/core.module';
import { TrackingService } from './tracking.service';
import { TrackingLoaderComponent } from './tracking.component';

@NgModule({
	declarations: [
		TrackingLoaderComponent,
	],
	providers: [
		FacebookService,
		TwitterAnalyticsService,
		BingService,
		OptimizelyService,
		GoogleAnalyticsService,
		HubspotTrackingService,
		LinkedinService,
		TrackingService
	],
	exports: [
		TrackingLoaderComponent,
	]
})
export class TrackingModule {
    constructor(@Optional() @SkipSelf() parentModule: TrackingModule) {
        if (parentModule) {
            throw new Error(
              'TrackingModule is already loaded at root. Import it in the AppModule only!');
          }
    }
}
