import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
	selector: 'amp-stepper',
	templateUrl: 'amp-stepper.component.html',
	styleUrls: ['amp-stepper.component.scss'],
})
export class AmpStepperComponent implements AfterViewInit {
	@ViewChild('stepper') public stepper: MatStepper;
	@Input() public steps = [];
	@Output() public changedStep = new EventEmitter();

	public constructor(private cdr :ChangeDetectorRef) {
		
	}

	public ngAfterViewInit() {
		this.stepper.selectedIndex = 1;
		this.cdr.detectChanges();
	}

	public changeStep(step) {
		this.changedStep.emit(step.selectedIndex)
	}

	public next() {
		this.steps[this.stepper.selectedIndex].completed = true;
		this.stepper.selected.completed = true;
		this.stepper.next();
	}
}